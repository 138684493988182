import Vue from 'vue'
import MasterApp from './MasterLayouts.vue'
import router from './router/index';
import AppLayout from './layouts/AppLayouts.vue';
import BlankLayout from './layouts/BlankLayouts.vue';
import axios from "./axios-main";
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuelidate from "vuelidate";
import VueMeta from 'vue-meta';
import VTooltip from 'v-tooltip';
import * as VueGoogleMaps from "vue2-google-maps";

// tailwind
import "./assets/sass/shell/_tailwind-stuff.sass";

Vue.component('app-layout', AppLayout)
Vue.component('blank-layout', BlankLayout)

Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(VueMeta);
Vue.use(VTooltip);
Vue.use(VueGoogleMaps, {
  load: {
    key: (process.env.VUE_APP_GMAP_API_KEY ? process.env.VUE_APP_GMAP_API_KEY : ''),
    libraries: ["places", "visualization"] // necessary for places input
  }
});

Vue.config.productionTip = false

window.Event = new Vue();

router.beforeEach((to, from, next) => {
	// console.log('router.beforeEach()')
	// console.log(store.state.auth.token)

  if (store.state.auth.token && store.state.auth.token !== null) {
    axios.defaults.headers.common["x-authorization"] = "Bearer " + store.state.auth.token;
  } else {
    axios.defaults.headers.common["x-authorization"] = null;
  }

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.token === null) {
      next({
        path: '/login',
      })
    } else {
      // if (store.state.auth.token) {
      if (store.state.auth.token && store.state.auth.token !== null) {

        // handle role tracking
        if (store.state.auth.user.role && store.state.auth.user.role.label === 'tracking' && to.name === 'DashboardPage') {
          next({
            path: '/tracking-history',
          });

        } else {
          next();
        }

      } else {
        store.state.auth.token = null;
        next({
          path: '/login',
        });
      }
    }
  } else {
    if (store.state.auth.token !== null && (to.name === 'Authlogin' || to.name === 'ForgotPasswordPage')) {

      // handle role tracking
      if (store.state.auth.user.role && store.state.auth.user.role.label === 'tracking') {
        next({
          path: '/tracking-history',
        });

      } else {
        next({
          path: '/',
        });
      }

    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(MasterApp),
}).$mount('#app');
