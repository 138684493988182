<template lang="pug">
    .app-layouts
        HeaderApp
            
        main.app-layouts__main
            router-view
</template>

<script>
import { mapState } from 'vuex';
import HeaderApp from '@/components/HeaderApp'

export default {
    name: 'AppLayout',
    components: {
        HeaderApp,
    },

    computed: {
        ...mapState('auth', ['user']),
    },
}
</script>
<style lang="sass">
    @import '@/assets/sass/shell/main.sass'
</style>