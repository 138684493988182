<template lang="pug">
  .box-loading.flex.items-center.justify-center
    .wrapper-loading(v-show="show")
      .flex.items-center.justify-center
        span.block.mr-2 Loading
        .animate-loading
          .circle
          .circle
          .circle
</template>

<script>
export default {
  name: 'Loading',
  props: ['show'],
}
</script>

<style lang="sass">
.box-loading
  position: relative
  width: 100%
  min-height: 400px

  .animate-loading
    position: relative

.circle
  width: 4px
  height: 4px
  position: absolute
  border-radius: 50%
  background-color: #3c3c3c
  left: 0px
  transform-origin: 50%
  animation: circle .5s alternate infinite ease

  &:nth-child(2)
    left: 8px
    animation-delay: .2s

  &:nth-child(3)
    left: 16px
    animation-delay: .3s

@keyframes circle
  0%
    opacity: 0

  40%
    opacity: 1

  100%
    opacity: 0

.wrapper-loading span
  font-size: 1em
  color: #626262
  left: 15%
</style>
