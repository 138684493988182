<template lang="pug">
    .not-found
        img.logo-global(alt="Vue logo" :src="logo")
        h1.text-3xl.mb-4 403
        h1.text-3xl.mb-4 Forbidden
        p.mb-6 You are not allowed to access this page.
        router-link.btn-primary.mt-6(:to="user && user.role && user.role.label === 'tracking' ? '/tracking-history' : '/'") Home

</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ForbiddenPage',
    data(){
        return {
            logo: this.$store.getters['config/getLogo'],
        };
    },
    computed: {
        ...mapState('auth', ['user']),
    },
}
</script>

<style lang="sass" scoped>
.not-found
    text-align: center
    .logo-global
        max-width: 250px
        margin: 0 auto 24px auto

    max-width: 420px
    margin: 32px auto

    .btn-primary
        letter-spacing: 1px

    h1
        color: #ffc490
</style>