<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold List of Target
                button.btn-primary(
                    @click="modalTarget = !modalTarget; form_type = true;"
                ) Add New Target

            .box__body
                .box-filter.mb-4
                    .flex.items-center
                        .field.relative.mr-3.field-filter
                            input.field-style(
                                v-model="keyword"
                                type="text"
                                placeholder="Search name or phone..."
                                @keyup="searchKeyword"
                            )
                            .field-icon
                                IconSet(
                                    type="search"
                                )
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data, index) in list.data" :key="index")
                                    td {{ data.target_name ? data.target_name : '-' }}
                                    td {{ data.clues && data.clues.length > 0 ? filterMsisdn(data.clues) : '-' }}
                                    td 
                                        .text-xs {{ data.updated_at? data.updated_at : '-' }}
                                    td
                                        .flex.items-center.justify-end
                                            
                                            button.btn-primary-o.btn-primary-o--disabled.mr-4.btn-small(v-if="status_quota_info.status === 'loading'" disabled="disabled")
                                                Spinner(show="true" size="20")

                                            template(v-else)
                                                template(v-if="Object.keys(quota_info).length > 0")

                                                    button.btn-primary-o.btn-primary-o--disabled.mr-4.btn-small.cursor-not-allowed(v-if="quota_info.used === quota_info.quota" disabled="disabled") Out of Quota

                                                    //- :to="{ name: 'SearchResult', params: {key: encrypt(filterMsisdn(data.clues, true))} }"
                                                    router-link.btn-primary-o.mr-4.btn-small(
                                                        v-else
                                                        :to="{ name: 'SearchResult', params: {key: encrypt(filterMsisdn(data.clues, true)), target_id: data.id} }"
                                                    ) Search Location

                                            button.btn-icon(
                                                @click="editModal(data.id)"
                                                v-tooltip="'Edit'"
                                            )
                                                IconSet(type="edit" size="s")

                                            button.btn-icon(
                                                @click="deleteData(data.id, data.target_name)"
                                                v-tooltip="'Delete'"
                                            )
                                                IconSet(type="delete" size="s")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

            .box__footer(v-if="status_list.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

        .overlay(v-bind:class="{'is-show': modalTarget}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold {{ form_type ? 'Add' : 'Edit'}} Target
                        button.btn-icon(
                            @click="modalTarget = !modalTarget"
                        )
                            IconSet(
                                type="close"
                            )
                    form.box-overlay__body(@submit="submit($event)")
                        .field
                            .field__label
                                label(for="search") Target Name <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="text"
                                    placeholder="Write target name here"
                                    v-model="model.target_name"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.target_name.$error") This target name is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_target_name !== ''") {{ error_target_name }}

                        .field
                            .field__label
                                label(for="search") Phone Number (MSISDN) <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="number"
                                    placeholder="Write phone number here"
                                    v-model="model.clues"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.clues.$error") This phone number (MSISDN) is required.
                                    .message.text-xs.text-red-500(v-if="error_clues !== ''") {{ error_clues }}

                        .field
                            .field__label
                                label(for="search") Select a case 
                                    sup(v-if="getRequiredCase") *
                                //- label(for="search") Select a case

                            .field__form

                                .relative(v-if="status_search_list.status === 'loading'")
                                    .field-style.field-style--mirroring.field-style--loading
                                        .absolute.w-full.h-full.flex.items-center.justify-center
                                            Spinner(show="true" size="32")

                                .relative
                                    select.field-style(v-model="model.case_id")
                                        option(selected value="") - Select Case -
                                        template(v-if="search_list.data && search_list.data.length > 0")
                                            option(v-for="opt in search_list.data" :key="opt.id" :value="opt.id") {{ opt.operation_name }}

                                    .field-icon
                                        IconSet(
                                            type="chevron-down"
                                        )

                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.case_id.$error") This case is required.
                                    .message.text-xs.text-red-500(v-if="error_case_id !== ''") {{ error_case_id }}

                        .field.flex.items-center.justify-end
                            button.btn-primary(
                                type="submit" 
                            ) SAVE

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
// , requiredIf
import { encrypt } from '@/util/crypto';

export default {
    name: "Target",
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        IconSet,
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Name',
                },{
                    name: 'Phone Number',
                },{
                    name: 'Latest Updated',
                },{
                    name: '',
                    action: true,
                }
            ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            order_by: '',
            keyword: '',
            timeout: null,

            modalTarget: false,
            form_type: true,
            target_id_selected: '',

            model: {
                target_name: '',
                clues: '',
                case_id: '',
            },

            // error field
            error_target_name: '',
            error_clues: '',
            error_case_id: '',

            getRequiredCase: this.$store.getters['config/getRequiredCase'],

        };
    },
    validations: {
        model: {
            target_name: {
                required,
                maxLength: maxLength(255)
            },
            clues: {
                required,
                // maxLength: maxLength(255),
                // numeric,
            },
            // case_id:  {
            //     required,
            // },
            case_id : {
                required: requiredIf(function(){
                    return this.getRequiredCase;
                })
            }
        },
    },
    computed: {
        ...mapState('target', [
            'status_list',
            'status_detail',
            'list',
            'detail',
        ]),
        ...mapState('caseManagement', [
            'status_search_list',
            'search_list',
        ]),
        ...mapState('dashboard', [
            'status_quota_info',
            'quota_info',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'TargetPage') {
                // this.total_data = 0;
                this.items_per_page = 10;
                this.page = 0;
                this.keyword = '';
                this.$store.commit('target/setList', {});

                this.getData();
            }
        },
        list(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = newData?.total_data ? parseInt(newData.total_data) : 0;
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        modalTarget(newData, oldData) {
            if (newData === true && oldData === false) {
                // var input = document.getElementById("input-search-target");
                // if (input)
                //     input.select();

                this.$store.dispatch('caseManagement/getSearchList', [100, 1]);
                // this.setEmpty();
            } else {
                this.$store.commit('caseManagement/setSearchList', {});
                // this.target_id_selected = '';
                // this.setEmptyModel();
                this.reset();
            }
        },
        detail(newData) {
            this.target_id_selected = (newData.id ? newData.id : '');
            this.model.target_name = (newData.target_name ? newData.target_name : '');
            this.model.clues = (newData.clues ? this.filterMsisdn(newData.clues, true) : '');
            this.model.case_id = (newData.case_id && newData.case_id[0] ? newData.case_id[0].id : '');
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'TargetPage') {
                this.$store.dispatch('target/getList', [this.items_per_page, (this.page + 1), this.keyword, this.order_by, this.from_date, this.to_date]);
                this.$store.dispatch('dashboard/getQuotaInfo');
                this.target_id_selected = '';
            }
        },
        encrypt(msisdn) {
            return encrypt(msisdn + '&&msisdn')
        },
        filterMsisdn(clues, clear=false) {
            let msisdn = (clear === false ? '-' : '');
            let filtered = clues.filter(function(item) {
                return item.name == 'msisdn';
            });

            if (filtered[0] && filtered[0].value)
                msisdn = filtered[0].value;

            return msisdn;
        },

        // for create or update target
        editModal(id) {
            this.modalTarget = !this.modalTarget;
            this.form_type = false;
            this.target_id_selected = id;
            this.getDetail(id);
        },
        getDetail(id) {
            this.reset();

            // this.$store.dispatch('caseManagement/getSearchList', [100, 1]);


            Event.$emit('showLoading', true);

            this.$store.dispatch('target/getDetail', id)
            .then((resp) => {
                if (resp === 'success') {

                    Event.$emit('showLoading', false);

                } else {
                    Event.$emit('error');
                }
            });

        },
        reset() {
            this.$store.commit('target/setDetail', {});
            this.target_id_selected = '';
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0,0);
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    // if(["clues","case_id",'additional_info'].indexOf(key) > -1)
                    //     this.model[Object.keys(this.model)[key]] = [];
                    // else
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_target_name= '';
                this.error_clues= '';
                this.error_case_id= '';

            } else if (empty !== true && errors !== null) {
                this.error_target_name = (errors.target_name ? errors.target_name : '');
                this.error_clues = (errors.clues ? errors.clues : '');
                this.error_case_id = (errors.case_id ? errors.case_id : '');
            }
        },
        // submit(e, save_continue=false) {
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            Event.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map(
                (key) => (["clues","case_id"].indexOf(key) > -1) ? JSON.stringify( (key === 'clues' ? [{name: 'msisdn', value: this.model[key], detail: []}] : (this.model[key] ? [{id: this.model[key]}] : [] )) ) : this.model[key]
            );
            model_data.push('[]'); // additional_info

            if (this.form_type === true) {

                // this.$store.dispatch('target/createNew', model_data)
                this.$store.dispatch('target/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        Event.$emit('success', 'Data successfully created!');
                        this.modalTarget = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });

            // edit/update
            } else {

                model_data = [this.target_id_selected].concat(model_data);

                // this.$store.dispatch('target/updateNew', model_data)
                this.$store.dispatch('target/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        Event.$emit('success', 'Data successfully created!');
                        this.modalTarget = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Target : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    Event.$emit('showLoading', true);

                    this.$store.dispatch('target/delete', id)
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;
                            this.getData();
                            Event.$emit('success', 'Data successfully deleted!');

                        } else {
                            if (resp.data && resp.data.message) {
                                Event.$emit('error', resp.data.message);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('target/setList', {});
                self.getData();
            }, 650);
        },
    },
    mounted() {
        this.getData();
        console.log("this.getRequiredCase")
        console.log(this.getRequiredCase)
    },
}
</script>

<style lang="sass" scoped>
    %btn
        @apply rounded text-sm inline-flex items-center justify-center

    .field-filter
        border: 1px solid $color-light
        @extend %btn

        .field-style, .field-icon
            border-radius: 0.25rem
            display: inline-flex
            align-items: center
            justify-content: center
            font-size: 0.875rem
            line-height: 1.25rem
            height: 2.25rem
        
</style>