<template lang="pug">
    .box-auth
        h1.text-3xl.mb-4 Please login into your account 
        form.box-form.mt-8.pt-8
            .field
                .field__label
                    label Email Registered
                .field__form
                    input.field-style(
                        placeholder="Write Email here"
                        id="username"
                        type="email"
                        v-model="username"
                        required
                    )
                    span.block.pt-1.text-xs.text-red-600(v-if="error_username !== null") {{ error_username }}

            .field
                .field__label
                    label Password
                .field__form
                    input.field-style(
                        type="password"
                        placeholder="Write Your Password"
                        id="password" 
                        v-model="password"
                        required    
                    )
                    span.block.pt-1.text-xs.text-red-600(v-if="error_password !== null") {{ error_password }}

            .field
                button.btn-primary.w-full(
                    type='submit' 
                    @click="login"
                ) Login
        span.text-xs Forgot password? <router-link to="" class="link">  Please contact the admin </router-link>

</template>

<script>
export default {
    name: 'PageLogin',
    data(){
        return {
            app_name: process.env.VUE_APP_APP_NAME,
            username : "",
            password : "",
            error_username : null,
            error_password: null,
            logo: ''
        }
    },
    methods : {
        validateEmail(email) {
            /* eslint-disable no-useless-escape */
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        login(e){
            e.preventDefault();

            this.error_username = null;
            this.error_password = null;

            if (this.username.length === 0 || this.password.length === 0) {
                // alert('Username and password is required!');
                this.error_username = (this.username.length==0 ? 'Email is required.' : null);
                this.error_password = (this.password.length==0 ? 'Password is required.' : null);

                this.$swal.fire({
                    title: 'Username or password is required!',
                    icon: 'warning',
                    timer: 3000,
                });
                return;
            }

            // if use email
            if (!this.validateEmail(this.username)) {
                this.error_username = 'The email must be a valid email address.';
                this.$swal.fire({
                    title: 'Email must be a valid email address.',
                    icon: 'warning',
                    timer: 3000,
                });
                return;
            }

            // loading
            Event.$emit('showLoading');

            // with auth api
            this.$store.dispatch('auth/login', [this.username, this.password])
            .then((resp) => {
                if (resp.result === 'success') {
                    // this.$router.push({ name: 'PageDashboard' });

                    let redirect = '/';

                    if (resp.data && resp.data.role && resp.data.role.label === 'tracking')
                        redirect = '/tracking-history';

                    this.$router.push(redirect);

                    this.$swal.fire({
                        title: 'Welcome, '+ resp.data.name +'!',
                        icon: 'success',
                        timer: 3000,
                    });

                    this.username = '';
                    this.password = '';
                    this.error_username = null;
                    this.error_password = null;

                    if (document.getElementById('username'))
                        document.getElementById('username').focus();
                } else {
                    
                    this.error_username = (resp.data && resp.data.message ? resp.data.message : null);

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Failed login!',
                        text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                    });
                }
            });
        }
    },
    mounted() {
        if (document.getElementById('username'))
            document.getElementById('username').focus();
    },
}
</script>

<style lang="sass" scoped>
    h1
        color: #ffc490

    .field
        @apply text-left

    .link
        @apply text-blue-300 underline
        &:hover
            @apply text-yellow-500

    .btn-primary
        @apply rounded font-bold uppercase
        height: 48px
        padding: 0 2rem
        color: $color-dark
        background-color: $color-primary
        font-size: 1rem
        transition: all 0.3s
        &:hover
            background-color: darken($color-primary, 5%)
</style>
