import CryptoJS from 'crypto-js/crypto-js';
import moment from 'moment';

const secret_key = 'MEs3eWdBbGpablNiZ1NpUHlIUUNsbTE2MTU1MzUwOTE=';
 
export function encrypt(string, url_encode=true, dynamic_secret_key=false) {

    if (dynamic_secret_key === true)
        dynamic_secret_key = process.env.VUE_APP_PROJECT_IDF ? atob(process.env.VUE_APP_PROJECT_IDF) : '';
    let cryptobject = CryptoJS.AES.encrypt(string, (dynamic_secret_key !== false ? dynamic_secret_key : secret_key));

    // let encrypted = {
    //     key: cryptobject.key + '', // don't send this
    //     iv: cryptobject.iv + '', // don't send this
    //     salt: cryptobject.salt + '', // don't send this
    //     ciphertext: cryptobject.ciphertext + '', // don't send this
    //     str: cryptobject + '' // send or store this
    // } 
    // return encrypted;

    // let encrypted = (cryptobject + '').replace(/\//g, '%2F');
    let encrypted = (url_encode ? encodeURIComponent(cryptobject + '') : cryptobject + '');
    
    return encrypted;
}

export function decrypt(encrypted_string, url_encode=true, dynamic_secret_key=false) {

    if (dynamic_secret_key === true)
        dynamic_secret_key = process.env.VUE_APP_PROJECT_IDF ? atob(process.env.VUE_APP_PROJECT_IDF) : '';
    // encrypted_string = encrypted_string.replace(/%2F/g, '/');
    encrypted_string = (url_encode ? decodeURIComponent(encrypted_string) : encrypted_string);

    return CryptoJS.AES.decrypt(encrypted_string, (dynamic_secret_key !== false ? dynamic_secret_key : secret_key)).toString(CryptoJS.enc.Latin1);
}

export function encryptBase64(string) {
    return btoa(string);
}

export function decryptBase64(string) {
    return atob(string);
}

export function generateRandomString(length=25, encrypt=false) {
    const allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"]; 
    const allLowerAlpha = [..."abcdefghijklmnopqrstuvwxyz"]; 
    // const allUniqueChars = [..."~!@#$%^&*()_+-=[]\{}|;:'",./<>?"];
    const allNumbers = [..."0123456789"];

    const base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

    const generator = (base, len) => {
       return [...Array(len)]
        /* eslint-disable no-unused-vars */
        .map(i => base[Math.random()*base.length|0])
        .join('');
    };

    if (encrypt)
        return btoa(generator(base, length)+moment().unix());

    return generator(base, length)+moment().unix();
}
export function decryptAPI(data) {
    var master_key = process.env.VUE_APP_APP_KEY;

    // Decode the base64 data so we can separate iv and crypt text.
    var rawData = atob(data);
    // Split by 16 because my IV size
    var iv = rawData.substring(0, 16);
    var crypttext = rawData.substring(16);

    //Parsers
    crypttext = CryptoJS.enc.Latin1.parse(crypttext);
    iv = CryptoJS.enc.Latin1.parse(iv); 
    var key = CryptoJS.enc.Utf8.parse(master_key);

    // Decrypt
    var plaintextArray = CryptoJS.AES.decrypt(
        { ciphertext:  crypttext},
        key,
        {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
    );

    // Can be Utf8 too
    var resp = CryptoJS.enc.Latin1.stringify(plaintextArray);
    return resp;
}