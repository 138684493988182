import axios from "@/axios-main";
import { decryptAPI } from '@/util/crypto';

const auth = {
  namespaced: true,
  state: {
    token: null,
    user: null,
    profile: {},
    status_profile: {
			status: false,
			cancel: null,
			code: null,
		},
  },
  mutations: {
    authUser(state, userData) {
      state.user = userData.user;
      if (userData.token) {
        state.token = userData.token;
        axios.defaults.headers.common["x-authorization"] = "Bearer " + userData.token;
      }
    },
    // storeUser(state, user) {
    //   state.userInfo = user;
    // },
    clearAuth(state) {
      state.token = null;
      state.user = null;
      axios.defaults.headers.common["x-authorization"] = null;
    },
    setProfile(state, data) {
      state.profile = data;
    },
    setStatusProfile: (state, status) => {
			state.status_profile = status;
    },
  },
  getters: {
    isAuthenticated(state) {
      if (!state.token) return false;
      return true;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    }
  },
  actions: {
    login({ commit, dispatch }, [username, password]) {

      let params = new FormData();

      params.append('email', username);
      params.append('password', password);

      return axios.post('api/v1/auth/login', params)
      .then((resp) => {
        try {
          var dataApi = JSON.parse(decryptAPI(resp.data.data))
          if (dataApi && dataApi.token) {
            commit('authUser', dataApi);
            return {result: 'success', data: dataApi.user};
          } else {
            return {result: 'failed', data: dataApi};
          }
        } catch (error) {
          console.log('error login')
          console.log(error)
          return {result: 'failed', data: dataApi};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    logout({ commit, dispatch }) {

      return axios.delete('api/v1/auth/logout')
      .then((resp) => {
        // console.log(resp)
        if (resp.status === 200) {
          
          // commit('setDownloadApp', {});
          commit("clearAuth");

          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });

        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

      // router.push({ name: 'Authlogin' });
    },

    // getProfile({ commit, dispatch }, [id]) {
    getProfile({ commit, dispatch, getters }) {
      
      commit('setStatusProfile', {
				status: 'loading',
				cancel: null,
				code: null,
      });
      
      let params = new FormData();

      // params.append('id', id);
      params.append('id', getters.getUser.id);
    
      return axios.post('api/v1/profile', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let code = resp.response && resp.response.status ? resp.response.status : null;    
        // update local storage
        commit('authUser', { user: data });
        commit('setProfile', data);
        commit('setStatusProfile', {
          status: true,
          cancel: null,
          code: code,
        });
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null;
        commit('setStatusProfile', {
          status: 'failed',
          cancel: null,
          code: code,
        });
        dispatch('errors/handleApiResponse', (code), { root: true });

        return 'failed';
      });
    },

    updateProfile({ commit, dispatch, getters }, [name=null, email=null, phone=null, password=null]) {

      let params = new FormData();
      params.append('id', getters.getUser.id);
      params.append('name', name);
      params.append('email', email);
      params.append('phone', phone);
      // params.append('role_id', role_id);
      params.append('role_id', getters.getUser.role.id);
      // params.append('client_id', client_id);
      params.append('client_id', getters.getUser.client.id);
      // params.append('status', status);
      params.append('status', getters.getUser.status);
      params.append('password', password);

      return axios.post('api/v1/profile/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });

        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },
  },
};

export default auth;
