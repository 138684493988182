import router from "@/router";

const errors = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async handleApiResponse ({ commit }, [status_code=null, message='Something wrong, please try again.']) {
      if (status_code !== null) {
        switch(status_code) {
          case 401:
            await commit('auth/clearAuth', null, { root: true });

            if (router.currentRoute.name !== 'Authlogin')
              router.push({ name: 'Authlogin' });
            break;

          case 403:
            if (router.currentRoute.name !== 'PageForbidden')
              router.push({ name: 'PageForbidden' });
            break;

          case 416:
            Event.$emit('warning', message);
            break;

          case 417:
            Event.$emit('warning', message);
            break;

          case 421:
            Event.$emit('warning', message);
            break;

          case 422:
            break;

          case 429:
            Event.$emit('error', 'You have reached the limit, please try again after a few minutes.');
            break;
          
          default:
            Event.$emit('warning', message);
        } 
      }
    },
  }
};

export default errors;
