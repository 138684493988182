<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold Saved Location

            .box__body
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data, index) in list.data" :key="index")
                                    td {{ data.created_at ? data.created_at : '-' }}
                                    td {{ data.location_name ? data.location_name : '-' }}
                                    td {{ data.address ? data.address : '-' }}
                                    td
                                        .flex.items-center.justify-end
                                            
                                            //- button.btn-primary-o.btn-primary-o--disabled.mr-4.btn-small(v-if="status_quota_info.status === 'loading'" disabled="disabled")
                                                Spinner(show="true" size="20")

                                            //- template(v-else)
                                                template(v-if="Object.keys(quota_info).length > 0")

                                                    button.btn-primary-o.btn-primary-o--disabled.mr-4.btn-small.cursor-not-allowed(v-if="quota_info.used === quota_info.quota" disabled="disabled") Out of Quota

                                                    //- :to="{ name: 'SearchResult', params: {key: encrypt(data.phone), target_id: data.id, from_saved_location: true} }"
                                                    router-link.btn-primary-o.mr-4.btn-small(
                                                        v-else
                                                        :to="{ name: 'SearchResult', params: {key: encrypt(data.phone), target_id: data.id} }"
                                                    ) Visit Location


                                            router-link.btn-primary-o.mr-4.btn-small(
                                                :to="{ name: 'SearchResultHistory', params: {data_type: 'saved-location', history_data: encrypt(data)} }"
                                            ) Visit Location

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

            .box__footer(v-if="status_list.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { encrypt } from '@/util/crypto';

export default {
    name: "Location",
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        IconSet,
    }, 
    data(){
        return {
            tableThead: [
                {
                    name: 'DateTime',
                },{
                    name: 'Name',
                },{
                    name: 'Location',
                },{
                    name: '',
                    action: true,
                }
            ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            order_by: '',
            keyword: '',
            timeout: null,
        }
    },
    computed: {
        ...mapState('savedLocation', [
            'status_list',
            'list',
        ]),
        // ...mapState('dashboard', [
        //     'status_quota_info',
        //     'quota_info',
        // ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'SavedLocationPage') {
                // this.total_data = 0;
                this.items_per_page = 10;
                this.page = 0;
                this.keyword = '';
                this.$store.commit('savedLocation/setList', {});

                this.getData();
            }
        },
        list(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'SavedLocationPage') {
                this.$store.dispatch('savedLocation/getList', [this.items_per_page, (this.page + 1), this.keyword, this.order_by, this.from_date, this.to_date]);
                // this.$store.dispatch('dashboard/getQuotaInfo');
            }
        },
        encrypt(data) {
            return encrypt(JSON.stringify(data));
        },
        // filterMsisdn(clues) {
        //     let msisdn = '-';
        //     let filtered = clues.filter(function(item) {
        //         return item.name == 'msisdn';
        //     });

        //     if (filtered[0] && filtered[0].value)
        //         msisdn = filtered[0].value;

        //     return msisdn;
        // },
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="sass">
    .location-width
        max-width: 35rem
</style>