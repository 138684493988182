import moment from 'moment';

const config = {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {
		getDatetimeFormat() {
			return function (datetime, from_format = "YYYY-MM-DDThh:mm:ssZ", to_format = "DD MMM YYYY HH:mm:ss") {
				return moment(datetime, from_format).format(to_format);
			};
		},
		getDefaultDate() {
			// change from 7 days to 1 day
			return function (range_days = process.env.VUE_APP_DEFAULT_RANGE ? process.env.VUE_APP_DEFAULT_RANGE : 6) {
				// var range_days = 7;
				const today = new Date();
				var dd = today.getDate();
				var mm = today.getMonth() + 1;
				var yyyy = today.getFullYear();

				if (dd < 10) {
					dd = '0' + dd;
				}

				if (mm < 10) {
					mm = '0' + mm;
				}

				var to_date = yyyy + '-' + mm + '-' + dd;

				today.setDate(today.getDate() - range_days);
				dd = today.getDate();
				mm = today.getMonth() + 1;
				yyyy = today.getFullYear();

				if (dd < 10) {
					dd = '0' + dd;
				}

				if (mm < 10) {
					mm = '0' + mm;
				}

				var start_date = yyyy + '-' + mm + '-' + dd;

				return {
					from_date: start_date,
					to_date: to_date,
				};
			};
		},
		getDefaultImage() {
			return (process.env.VUE_APP_DEFAULT_IMAGE ? process.env.VUE_APP_DEFAULT_IMAGE : '/images/no-image.png');
		},
		getDefaultImageProfile() {
			return (process.env.VUE_APP_DEFAULT_IMAGE_PROFILE ? process.env.VUE_APP_DEFAULT_IMAGE_PROFILE : '/images/no-image-profile.jpg');
		},
		getTitleGlobal() {
			return (process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : 'Nemesis Approval');
		},
		getLogo() {
			return (process.env.VUE_APP_APP_LOGO ? process.env.VUE_APP_APP_LOGO : '/images/logo.svg');
		},
		getLogoGlobalHeader() {
			return (process.env.VUE_APP_APP_LOGO_GLOBAL_HEADER ? process.env.VUE_APP_APP_LOGO_GLOBAL_HEADER : '/images/logo.svg');
		},
		getFavicon() {
			return (process.env.VUE_APP_APP_FAVICON ? process.env.VUE_APP_APP_FAVICON : '/favicon.ico');
		},
		getMaxImageSize() {
			// in MB
			if (process.env.VUE_APP_MAX_IMAGE != undefined) {
				return process.env.VUE_APP_MAX_IMAGE
			} else {
				return 10
			}
		},
		getValidateRange() {
			return (process.env.VUE_APP_VALIDATE_RANGE ? process.env.VUE_APP_VALIDATE_RANGE : 365);
		},
		getDateRangeByRoute() {
			return function (route=null) {
				let range = process.env.VUE_APP_DEFAULT_RANGE ? process.env.VUE_APP_DEFAULT_RANGE : 6;
				return range
			}
		},
		getMapVendor() {
			return typeof process.env.VUE_APP_VENDOR_MAP !== undefined ? process.env.VUE_APP_VENDOR_MAP : 'gmap';
		},
		getRequiredCase() {
			return (process.env.VUE_APP_REQUIRED_CASE == 'false' ? false : true);
		},
		getTimezone() {
			return 	process.env.VUE_APP_DEFAULT_TIMEZONE ? process.env.VUE_APP_DEFAULT_TIMEZONE:'Asia/Jakarta';
		},
	},
	actions: {},
};

export default config;