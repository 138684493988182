export default {
	API_URL: process.env.VUE_APP_API_URL,
	APP_KEY: process.env.VUE_APP_APP_KEY,
	SECRET_KEY: process.env.VUE_APP_SECRET_KEY,
	AUTH_HEADERS: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Accept': 'application/json',
		'Authorization': 'Basic ' + btoa(process.env.VUE_APP_SERVER_AUTH_USERNAME +':'+ process.env.VUE_APP_SERVER_AUTH_PASSWORD),
		'x-app-key': process.env.VUE_APP_APP_KEY,
		'x-secret-key': process.env.VUE_APP_SECRET_KEY,
		'x-authorization': null,
	},
	TIMEZONE: process.env.VUE_APP_DEFAULT_TIMEZONE ? process.env.VUE_APP_DEFAULT_TIMEZONE:'Asia/Jakarta',
};
