<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold Search Result
                //- v-if="route_name === 'SearchResult' && from_saved_location === undefined"
                button.btn-primary(
                    v-if="route_name === 'SearchResult'"
                    :class="Object.keys(recent_location).length > 0 ? '' : 'btn-disabled cursor-not-allowed'"
                    :disabled="Object.keys(recent_location).length > 0 && savedLocation === false ? false : 'disabled'"
                    @click="modalLocation = !modalLocation"
                ) Save Target Location

            .box__body
                .flex.flex-wrap(
                    v-if="status_recent_location.status === 'loading'"
                    class="lg:-mx-2"
                )
                    Loading(show="true")

                template(v-else)
                    .mb-12
                        .mb-4
                            p {{ recent_location.features && recent_location.features[0].properties.alamat ? recent_location.features[0].properties.alamat : '-' }}

                        template(v-if="Object.keys(recent_location).length > 0 && mapLoaded")
                            //- .block(class="w-full lg:w-8/12")
                            .box-maps.w-full.bg-black(style="height:450px")
                                template(v-if="mapVendor === 'osm'")
                                    l-map(:id="idOsm" :zoom="10" :center="center" :options="options" style="height: 100%; width: 100%;")
                                        l-tile-layer(:url="url" :attribution="attribution")
                                        l-marker(v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index")
                                            l-popup(:content="location.name")
                                        //- LeafletHeatmap(:lat-lng="latLngHeat" :max="maxHeat" :radius="radHeat" :maxZoom="10")

                                template(v-else)
                                    //- .box-map
                                    gmap-map(ref="mapRef" :center="center" :zoom="10" :options="options" style="width:100%; height: 100%;")

                        //- .block(v-else class="w-full lg:w-8/12")
                        .box-maps.w-full.bg-black(v-else style="height:450px")
                            //- .box-map
                            NoDataAvailable
                    
                    .block
                        h3.text-xl.font-bold.mb-8 Detail Devices
                        .flex.w-full.justify-between
                            .block
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm MSISDN
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.msisdn ? recent_location.features[0].properties.msisdn : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm REGISTERED NAME
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.target_name ? recent_location.features[0].properties.target_name : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm IMSI
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.imsi ? recent_location.features[0].properties.imsi : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm IMEI
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.imei ? recent_location.features[0].properties.imei : '-' }}
                            .block
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm DEVICE NAME
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.ttype ? recent_location.features[0].properties.ttype : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm OPERATOR NAME 
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.provider ? recent_location.features[0].properties.provider : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm MCC
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.mcc ? recent_location.features[0].properties.mcc : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm ENB
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.enb ? recent_location.features[0].properties.enb : '-' }}
                            .block
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm LAC
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.lac ? recent_location.features[0].properties.lac : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm CI
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.ci ? recent_location.features[0].properties.ci : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm BTS ID 
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.bts ? recent_location.features[0].properties.bts : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm TAC
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.tac ? recent_location.features[0].properties.tac : '-' }}
                            .block
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm RAT
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.net ? recent_location.features[0].properties.net : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm STATE
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.state ? recent_location.features[0].properties.state : '-' }}
                                .mb-6
                                    span.block.mb-2.opacity-50.text-sm TIME STAMP
                                    p.text-sm {{ recent_location.features && recent_location.features[0].properties.requested_at ? recent_location.features[0].properties.requested_at : '-' }}

        .overlay(v-bind:class="{'is-show': modalLocation}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold Save Location
                        button.btn-icon(
                            @click="modalLocation = !modalLocation"
                        )
                            IconSet(
                                type="close"
                            )
                    form.box-overlay__body(@submit="createLocation($event)")
                        .mb-6
                            span.block.mb-2.opacity-50.text-sm Location
                            p {{ recent_location.features && recent_location.features[0].properties.alamat ? recent_location.features[0].properties.alamat : '-' }}

                        .field
                            .field__label
                                label(for="name") Location Name
                            .field__form
                                input.field-style#input-location-name(
                                    type="text"
                                    placeholder="Write location name here"
                                    v-model="model.location_name"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.location_name.$error") This location name is required.
                                    .message.text-xs.text-red-500(v-if="error_location_name !== ''") {{ error_location_name }}
                        
                        .field.flex.items-center.justify-end
                            button.btn-primary(
                                type="submit" 
                                @click="createLocation($event)"
                            ) SAVE

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import { decrypt } from '@/util/crypto';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Loading from '@/components/Loading.vue';
import { gmapApi } from 'vue2-google-maps';
import { required } from "vuelidate/lib/validators";

// open street map
import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
// import LeafletHeatmap from '@/components/LeafletHeatmap.vue';
// import 'leaflet.heat';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = '';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: "SearchResult",
    components: {
        IconSet,
        NoDataAvailable,
        Loading,

        // open street map
        LMap,
        GestureHandling,
        LTileLayer,
        LMarker,
        LPopup,
        // LeafletHeatmap,
    }, 
    data(){
        return {
            modalLocation: false,
            savedLocation: false,

            infoWindows: [],
            mapVendor: this.$store.getters['config/getMapVendor'],
            mapLoaded: false,
            
            // google map
            options: {
                // zoomControl: true,
                // mapTypeControl: true,
                // scaleControl: true,
                // streetViewControl: true,
                // rotateControl: true,
                // fullscreenControl: false,
                // disableDefaultUi: false
                // scrollwheel: false,
                gestureHandling: 'cooperative',
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                        featureType: 'administrative.locality',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{color: '#263c3f'}]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#6b9a76'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{color: '#38414e'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#212a37'}]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#9ca5b3'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [{color: '#746855'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{color: '#1f2835'}]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#f3d19c'}]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{color: '#2f3948'}]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#d59563'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{color: '#17263c'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [{color: '#515c6d'}]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.stroke',
                        stylers: [{color: '#17263c'}]
                    }
                ],
            },
            center: {},
            markers: [],
            places: [],
            // myLatlng: null,

            // openstreetmap
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            locations: [],
            idOsm: 'openstreetmap-recent_location',
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            // latLngHeat: [],
            // maxHeat: 1.0,
            // radHeat: 30,


            model: {
                location_name: '',
            },

            error_location_name: '',
        };
    },
    validations: {
        model: {
            location_name: {
                required,
            },
        },
    },
    computed: {
        ...mapState('recentLocation', [
            'status_recent_location',
            'recent_location',
        ]),
        route() {
            return this.$route;
        },
        route_name() {
            return this.$route.name;
        },
        key() {
            return this.$route.params.key;
        },
        isKey() {
            return this.key ? true : false;
        },
        google: gmapApi,
        target_id() {
            return this.$route.params.target_id;
        },
        // from_saved_location() {
        //     return this.$route.params.from_saved_location;
        // },

        // for result history
        data_type() {
            return this.$route.params.data_type;
        },
        history_data() {
            return this.$route.params.history_data;
        },
    },
    watch: {
        route(newRoute, oldRoute) {

            // console.log(newRoute, oldRoute);

            // if(newRoute.matched[0].name === 'pageTargetDetail'){
            //     this.isTargetDetail = true
            // } else {
            //     this.isTargetDetail = false
            // }
            // if (
            //         (
            //             newRoute.matched[0].name === 'ResultShell' && oldRoute.matched[0].name === 'ResultShell'
            //         ) || (
            //             newRoute.matched[0].name === 'ResultRealtimeShell' && oldRoute.matched[0].name === 'ResultRealtimeShell'
            //         ) || (
            //             newRoute.matched[0].name === 'ResultHistoricalShell' && oldRoute.matched[0].name === 'ResultHistoricalShell'
            //         ) || (
            //             newRoute.matched[0].name === 'pageTargetDetail' && oldRoute.matched[0].name === 'pageTargetDetail'
            //         )
            //     ) {
            //     this.getDataOnRouteChange(newRoute, oldRoute);
            // } else if (newRoute.matched[0].name === 'ResultHistoricalShell' && oldRoute.matched[0].name === 'ResultRealtimeShell') {
            //     this.getDataOnRouteChange(newRoute, oldRoute, true);
            // }
        },
        route_name() {},
        async key(newData, oldData) {
            // console.log('===== key')
            // console.log(newData, oldData)

            // await this.setEmptyResultData('all', true, true);
            await this.getData();
        },
        isKey() {},
        recent_location () {
            this.startMap();
        },
        google() {},
        target_id() {},
        // from_saved_location() {},
        modalLocation(newData, oldData) {
            if (newData === true && oldData === false) {
                var input = document.getElementById("input-location-name");
                if (input)
                    input.focus();

                this.setEmptyModel();
            }
        },

        // for result history
        data_type() {},
        history_data() {
            this.getData();
        },
    },
    methods: {
        async setEmptyResultData(set='all') {
        // async setEmptyResultData(set='all', reset_date_range=false, stop=false) {
            // console.log('masuk setEmptyResultData')
            // let def = this.$store.getters['config/getDateRangeByRoute'](this.route.matched[1].name)
            // const default_date = this.$store.getters['config/getDefaultDate'](def);

            // set empty all result data
            // if (set === 'all' || set === 'subheader')
            //     await this.$store.dispatch('subheader/setEmpty');

            if (set === 'all' || set === 'recentLocation') {
                // if (reset_date_range) {
                //     await this.$store.commit('recentLocation/setFromDate', default_date.from_date);
                //     await this.$store.commit('recentLocation/setToDate', default_date.to_date);
                // }
                await this.$store.dispatch('recentLocation/setEmpty');
            }
        },
        async getData() {

            // get api recent location
            if (this.route_name === 'SearchResult' && this.isKey && this.target_id) {

                // let get_data_id = await generateRandomString(40);
                // this.get_data_id = get_data_id;
                // // console.log(get_data_id)
                // if (get_data_id !== this.get_data_id)
                //     return;

                let decrypt_data = decrypt(this.key);
                decrypt_data = decrypt_data.split('&&');

                var key = decrypt_data[0];
                var type = decrypt_data[1];

                // console.log(key, type)

                this.savedLocation = false;

                // await this.$store.dispatch('recentLocation/getResultData', [key, type]);)
                await this.$store.dispatch('recentLocation/getResultData', [key, type, 'service', this.target_id]);
            }

            // get from data history
            if (this.route_name === 'SearchResultHistory' && this.data_type && this.history_data) {

                let history_data = {};

                if (this.data_type === 'tracking-history') {

                    history_data = this.jsonParse(decrypt(this.history_data));

                } else if (this.data_type === 'saved-location') {

                    history_data = this.jsonParse(decrypt(this.history_data), false);

                    // format data from response api

                    // {
                    //     "id": "606d74b24c406c63440119b0",
                    //     "location_name": "saved loc manisia",
                    //     "user_id": "9ea11260-7f6d-411a-ab56-fee4b1e1a52f",
                    //     "registered_name": "manisia",
                    //     "phone": "6281391684667",
                    //     "role_id": "226",
                    //     "client_id": "117",
                    //     "address": "JAWA TENGAH, SRAGEN, KARANGMALANG, KROYO, SURAKARTA, SRAGEN",
                    //     "coordinate": {
                    //         "lat": "-7.43912",
                    //         "lng": "111.03024"
                    //     },
                    //     "target": {
                    //         "id": "606d63194c406c634401181c",
                    //         "name": "manisia",
                    //         "label": "#cc88b1",
                    //         "photo": ""
                    //     },
                    //     "bts": "510100360540614",
                    //     "imsi": "510109132684667",
                    //     "imei": "3532910702617924",
                    //     "mcc": "510",
                    //     "lac": "3605",
                    //     "ci": "40614",
                    //     "net": "3G",
                    //     "ttype": "Apple iPhone 6S Plus (A1687)",
                    //     "provider": "TELKOMSEL",
                    //     "state": "idle",
                    //     "tac": "",
                    //     "enb": "",
                    //     "created_at": "2021-04-07 16:00:34+0700",
                    //     "updated_at": "2021-04-07 16:00:34+0700"
                    // }

                    history_data = {
                        "features": [
                            {
                                "geometry": {
                                    "coordinates": [
                                        (history_data.coordinate && history_data.coordinate.lng ? history_data.coordinate.lng : ''),
                                        (history_data.coordinate && history_data.coordinate.lat ? history_data.coordinate.lat : '')
                                    ]
                                },
                                "properties": {
                                    "alamat": (history_data.address ? history_data.address : ''),
                                    // "aol": "0",
                                    // "azimuth": null,
                                    "ci": (history_data.ci ? history_data.ci : ''),
                                    "country": (history_data.country ? history_data.country : ''),
                                    "dtm": (history_data.dtm ? history_data.dtm : ''),
                                    "imei": (history_data.imei ? history_data.imei : ''),
                                    "imsi": (history_data.imsi ? history_data.imsi : ''),
                                    "lac": (history_data.lac ? history_data.lac : ''),
                                    "mcc": (history_data.mcc ? history_data.mcc : ''),
                                    "mnc": (history_data.mnc ? history_data.mnc : ''),
                                    "msisdn": (history_data.phone ? history_data.phone : ''),
                                    // "name": "",
                                    "net": (history_data.net ? history_data.net : ''),
                                    // "radius": "",
                                    // "roaming": "",
                                    // "status": "Successfully",
                                    "ttype": (history_data.ttype ? history_data.ttype : ''),
                                    // "code": "0",
                                    // "qtime": "0:00:02.729",
                                    "state": (history_data.state ? history_data.state : ''),
                                    // "state_code": "0",
                                    "bts": (history_data.bts ? history_data.bts : ''),
                                    "tac": (history_data.tac ? history_data.tac : ''),
                                    "enb": (history_data.enb ? history_data.enb : ''),
                                    "provider": (history_data.provider ? history_data.provider : ''),
                                    "requested_at": (history_data.created_at ? history_data.created_at : ''),
                                    // "old_imsi": null,
                                    // "old_imei": null,
                                    "target_id": (history_data.target && history_data.target.id ? history_data.target.id : ''),
                                    "target_name": (history_data.registered_name ? history_data.registered_name : '')
                                },
                                "type": "Feature"
                            }
                        ],
                        "type": "FeatureCollection"
                    };
                }

                if (Object.keys(history_data).length > 0) {
                    await this.$store.commit('recentLocation/setStatus', 'loading');
                    await this.$store.commit('recentLocation/setStatusRecentLocation', {
                        status: 'loading',
                        cancel: null,
                        code: null
                    });

                    await this.$store.commit('recentLocation/setRecentLocation', history_data);

                    await this.$store.commit('recentLocation/setStatusRecentLocation', {
                        status: true,
                        cancel: null,
                        code: null
                    });
                    await this.$store.commit('recentLocation/setStatus', true);
                }
            }
        },
        startMap() {
            var self = this;
            var location = [];

            // gmap
            self.infoWindows = [];
            self.markers = [];

            // osm
            self.locations = [];
            // self.latLngHeat = [];

            if(typeof this.recent_location !== undefined && typeof this.recent_location !== 'undefined' && this.recent_location !== null && Object.keys(this.recent_location).length > 0){
                location = this.recent_location
            } else {
                return;
            }                

            var result = location.features[0];

            var lng = parseFloat(result.geometry.coordinates[0] ? result.geometry.coordinates[0] : 0);
            var lat = parseFloat(result.geometry.coordinates[1] ? result.geometry.coordinates[1] : 0);

            // for marker
            // var dtm = result.properties.dtm ? moment(result.properties.dtm, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY, HH:mm') : '-';
            var dtm = result.properties.requested_at ? result.properties.requested_at : '-';
            var bts = result.properties.bts ? result.properties.bts : '-';
            var state = result.properties.state ? result.properties.state : '-';
            var name = result.properties.target_name ? result.properties.target_name : '-';

            if (Number.isNaN(lat) || Number.isNaN(lng))
                return;

            this.center = {
                lat: lat,
                lng: lng,
            };

            this.mapLoaded = true;

            var contentString = '<div id="content">'+
                '<div id="bodyContent" style="color: #202020;">'+
                    '<table>'+
                        '<tr>'+
                            '<td class="pr-1">Last Updated</td>'+
                            '<td class="pr-1">:</td>'+
                            '<td>'+dtm+'</td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td>BTS</td>'+
                            '<td>:</td>'+
                            '<td>'+bts+'</td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td>State</td>'+
                            '<td>:</td>'+
                            '<td>'+state+'</td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td>Link Location</td>'+
                            '<td>:</td>'+
                            '<td><a target="_blank" href="https://www.google.com/maps?q='+lat+','+lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                            '</td>'+
                        '</tr>'+
                    '</table>'+
                '</div>'+
            '</div>';

            switch(this.mapVendor) {
                case 'osm':
                    // eslint-disable-next-line no-case-declarations
                    let centerTmp = [self.center['lat'], self.center['lng']];
                    self.locations.push({name:contentString,position:centerTmp});
                    break;

                default:
                    self.$nextTick(() => {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            const google = self.google;

                            map.center = new google.maps.LatLng(self.center);
                            map.zoom = 10;

                            let marker = new google.maps.Marker({
                                position: new google.maps.LatLng(self.center),
                                map: map,
                                title: name,
                            });

                            let infowindow = new google.maps.InfoWindow({
                                content: contentString,
                                maxWidth: 300,
                            });

                            self.infoWindows.push(infowindow);
                            google.maps.event.addListener(marker, 'click', function() {
                                infowindow.open(map, marker);
                            });
                        });
                    });
                    break;
            }
        },
        
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_location_name = '';

            } else if (empty !== true && errors !== null) {
                this.error_location_name = (errors.location_name ? errors.location_name : '');
            }
        },
        createLocation(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            Event.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map((key) => this.model[key]); // locatinon_name

            const geometry = this.recent_location.features[0].geometry.coordinates;
            const properties = this.recent_location.features[0].properties;

            model_data.push(JSON.stringify({ // coordinate
                lat: parseFloat(geometry[1]),
                lng: parseFloat(geometry[0])
            }));
            let targetId = this.target_id
            try {
                if(![null,'','-',undefined].includes(properties.target_id)) {
                    console.log('masuuiukk')
                    targetId = properties.target_id
                }
            } catch (error) {
                targetId = this.target_i
            }
            

            model_data.push(targetId);
            model_data.push(properties.target_name); // registered_name
            model_data.push(properties.alamat); // address
            model_data.push(properties.msisdn); // phone
            model_data.push(properties.ttype);
            model_data.push(properties.bts);
            model_data.push(properties.imsi);
            model_data.push(properties.imei);
            model_data.push(properties.mcc);
            model_data.push(properties.lac);
            model_data.push(properties.ci);
            model_data.push(properties.net);
            model_data.push(properties.provider);
            model_data.push(properties.state);

            this.$store.dispatch('savedLocation/create', model_data)
            .then((resp) => {
                if (resp.result === 'success') {

                    this.modalLocation = false;
                    this.savedLocation = true;

                    Event.$emit('success', 'Data successfully created!');

                } else {
                    if (resp.data.message && resp.data.message.errors) {
                        this.setErrors(false, resp.data.message.errors);
                        // Event.$emit('error', null, resp.data.message.errors);
                        Event.$emit('showLoading', false);
                        window.scrollTo(0,0);
                    } else {
                        Event.$emit('error');
                    }
                }
            });
        },
        jsonParse(msg, use_replace=true) {

            if (use_replace) {
                msg = msg.replace("None None (0)", "None");
                // console.log('msg first', msg)
                msg = msg.replace(/'/g, '"').replace(/None/g, '"None"').replace(/\"\"None\"\"/g, '"None"').replace(/False/g, '"False"').replace(/'/g, '"').replace(/True/g, '"True"');

                msg = msg.replace(/"None"/g, 'null');
                // console.log('msg second', msg)
            }

            msg = JSON.parse(msg);
            // console.log('msg 3rd', msg)

            return msg;
        },
    },
    async mounted() {
        if (this.isKey)
            this.startMap();

        await this.setEmptyResultData();
        this.getData();
    },
}
</script>

<style lang="sass">
    .box-map
        color: #ccc
</style>
