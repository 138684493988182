<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-xl Activity Log
            .box__body
                .box-filter.mb-4
                    .flex.items-center
                        //- .btn-primary-o.relative.mr-3
                            .relative
                                .w-40
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="21")
                        .btn-primary-o.relative.mr-3
                            .relative
                                .w-40(v-if="device_status_list.status === 'loading'")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="21")
                                select.w-full#filter-client(v-else v-model="device_id")
                                    option(value="all") - All Device -
                                    template(v-if="device_list.data && device_list.data.length > 0")
                                        option(v-for="(device,index) in device_list.data" :key="index" :value="device.device_id") {{ device.name }}
                                //- .field-icon
                                    IconSet(
                                        type="chevron-down"
                                    )
                        .btn-primary-o.relative.mr-3
                            IconSet(type="calendar" size="s")
                            .btn-date-range.ml-3
                                date-range-picker(
                                    v-if="dateRange.startDate && dateRange.endDate" 
                                    ref="picker" 
                                    v-model="dateRange" 
                                    @update="pickerUpdate($event)" 
                                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                    :date-format="dateFormat" 
                                    opens="right"
                                    :ranges="default_ranges"
                                    autoApply=true
                                )
                                    div(
                                        slot="input" 
                                        slot-scope="picker") 
                                            | {{ picker.startDate | date}} - {{ picker.endDate  | date}}
                        //- Export to CSV
                        JsonExcel.btn-primary-o(
                            v-if="list.data && list.data.length > 0"
                            type = "csv"
                            :title="[exportTitle, from_date + ' - ' + to_date]"
                            :name="csvName"
                            :data="csvData"
                        )
                            IconSet(type="download" size="s")
                            span.m-hide.ml-3
                            | Download CSV
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data, index) in list.data" :key="index")
                                    //- td.text-ms {{ data.created_at }}
                                    td {{ !index? datetimeFormat(data.created_at, 'DD MMMM YYYY'):datetimeFormat(list.data[index-1].created_at, 'DD MMMM YYYY')==datetimeFormat(list.data[index].created_at, 'DD MMMM YYYY')? '':datetimeFormat(data.created_at, 'DD MMMM YYYY') }}
                                    td {{ data.target? data.target.name:'-' }}
                                    td {{ data.msisdn }}
                                    //- td {{ data.use_for | capitalize}}
                                    td {{ data.user?data.user.name:'-'}}
                                    td {{ data.user && data.user.device && data.user.device.name?data.user.device.name:'-',}}
                                    td.text-ms {{ data.created_at }}

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available   
            .box__footer(v-if="status_list.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )
            

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import moment from 'moment';
import JsonExcel from "vue-json-excel";

export default {
    name: 'HistoryActivity',
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        IconSet,
        JsonExcel,
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Datetime',
                },{
                    name: 'Target Name',
                },{
                    name: 'MSISDN',
                }
                // ,{
                //     name: 'Use For',
                // }
                ,{
                    name: 'User',
                },{
                    name: 'Device'
                },{
                    name: 'Latest Timestamp'
                }
            ],

            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            order_by: '',
            keyword: '',
            timeout: null,

            csvName: 'activity_log_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],

            exportTitle: 'Activity Log',
            device_id: 'all',
        }
    },
    computed: {
        ...mapState('activityLog', [
            'status_list',
            'list',
        ]),
        ...mapState({
            device_status_list: state => state.tacticalDevice.status_list,
            device_list: state => state.tacticalDevice.list,
        }),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'ActivityLogPage') {
                this.getData();
            }

            if (newData === 'ActivityLogPage') {
                if (oldData !== 'ActivityLogPage') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('activityLog/setList', {});
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
            if (this.list.data && this.list.data.length > 0) {
                this.getDataCsv(this.list.data)
            }
        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData();
        },
        keyword() {},
        device_id(newData) {
            if (newData != null) {
                this.getData();
            }
        },
    },
    methods: {
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
        getData() {
            this.$store.dispatch('activityLog/getList', [this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date, this.device_id]);

            this.pagination();
            if (this.list.data && this.list.data.length > 0) {
                this.getDataCsv(this.list.data)
            }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    datetime: item.created_at?this.datetimeFormat(item.created_at, 'DD MMMM YYYY'):'-',
                    target_name: item.target?item.target.name:'-',
                    msisdn: item.msisdn?item.msisdn:'-',
                    // use_for: this.$options.filters.capitalize(item.use_for)?item.use_for:'-',
                    user: item.user?item.user.name:'-',
                    device:item.user && item.user.device && item.user.device.name?item.user.device.name:'-',
                    latest_timestamp:item.created_at?this.datetimeFormat(item.created_at, 'DD MMMM YYYY HH:mm:ss [WIB]'):'-',
                };
            })
        },
        pageChange(page) {
            this.page = page;
        },
        rageChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        searchKeyword() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.page = 0;
                self.$store.commit('activityLog/setList', {});

                // if (self.keyword.length > 0) {
                    self.getData();
                // }
            }, 650);
        },
        datetimeFormat(datetime, toFormat="DD MMMM YYYY hh:mm", fromFormat='DD MMM YYYY HH:mm:ss z') {
            let date = moment(datetime, fromFormat).format(toFormat); 
            return date;
        },
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
    },
    mounted() {
        // console.log('=================== mounted on case-management index')
        if (this.route_name === 'ActivityLogPage') {
            this.$store.dispatch('tacticalDevice/getList', [200,1]);

            // reset date filter

            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.getData();
            // this.pagination();
        }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        capitalize: function (s) {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
    }
}
</script>

<style lang="sass">
    .btn-date-range
        .reportrange-text
            border: none
            padding: 0px

        .calendars.row
            display: flex
</style>

<style lang="sass" scoped>
    .callout
        @apply block bg-yellow-100 text-yellow-900 p-4 rounded
        span
            @apply block mb-2 uppercase text-xs font-bold
    %btn
        @apply rounded text-sm inline-flex items-center justify-center px-4 py-2

    .btn-primary-o
        border: 1px solid $color-light
        @extend %btn
        cursor: pointer
        &:hover
            color: $color-dark
            border: 1px solid $color-primary
            background-color: $color-primary
        
        select
            background-color: transparent


    .btn-default
        @extend %btn
        &:hover
            color: $color-dark
            background-color: $color-primary

    .main-table
        @apply w-full
        table.table
            @apply w-full
            tr
                th,
                td
                    @apply py-3 px-2
                    border-bottom: 1px solid darken($color-bg, 8%)

                th
                    @apply uppercase text-xs text-left opacity-75

                &:hover
                    td
                        background: darken($color-bg, 4%)

    .loading-request
        width: 40px
        height: 40px
        border-radius: 9999px
        border-width: 3px
        border-style: solid
        border-color: $color-primary darken($color-primary, 50%) $color-primary $color-primary
        transform: rotate(0deg)
        animation: rotate 1s infinite

    @keyframes rotate
        from
            transform: rotate(0deg)
        to
            transform: rotate(360deg)
</style>