import axios from "@/axios-main";
import axiosOrigin from "axios";

const cancelToken = axiosOrigin.CancelToken;

const history = {
	namespaced: true,
	state: {
		status_summary_data: {
			status: false,
			cancel: null,
			code: null,
		},
		summary_data: {},

		status_summary_history: {
			status: false,
			cancel: null,
			code: null,
		},
		summary_history: {},

		status_history_data: {
			status: false,
			cancel: null,
			code: null,
		},
		history_data: {},
	},
	mutations: {
		setStatusSummaryData: (state, status) => {
			state.status_summary_data = status;
		},

		setSummaryData: (state, data) => {
			state.summary_data = data;
		},

		setStatusSummaryHistory: (state, status) => {
			state.status_summary_history = status;
		},

		setSummaryHistory: (state, data) => {
			state.summary_history = data;
		},

		setStatusHistoryData: (state, status) => {
			state.status_history_data = status;
		},

		setHistoryData: (state, data) => {
			state.history_data = data;
		},
	},
	getters: {},
	actions: {
		getSummaryData({
			state,
			commit,
			dispatch
		}) {
			if (state.status_summary_data.cancel != null && state.status_summary_data.status === 'loading')
				state.status_summary_data.cancel('Operation canceled by the user.');

			const source = cancelToken.source();

			commit('setStatusSummaryData', {
				status: 'loading',
				cancel: source.cancel,
				code: null,
			});

			axios.post('api/v1/loc-approval/summary', {
					cancelToken: source.token
				})
				.then((resp) => {
					// console.log(resp.data)
					let data = resp.data;
					let code = resp.response && resp.response.status ? resp.response.status : null;
					commit('setSummaryData', data);

					commit('setStatusSummaryData', {
						status: true,
						cancel: null,
						code: code,
					});
				})
				.catch((resp) => {
					// console.log(resp)
					let code = resp.response && resp.response.status ? resp.response.status : null;
					commit('setStatusSummaryData', {
						status: 'failed',
						cancel: null,
						code: code,
					});
					dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
				});
		},

		getSummaryHistory({ state, commit, dispatch }) {
			if (state.status_summary_history.cancel != null && state.status_summary_history.status === 'loading')
				state.status_summary_history.cancel('Operation canceled by the user.');

			const source = cancelToken.source();

			commit('setStatusSummaryHistory', {
				status: 'loading',
				cancel: source.cancel,
				code: null,
			});

			// axios.post('api/v1/request-access/active-users', params, {
			// 	cancelToken: source.token
			// })

			axios.post('api/v1/request-access/summary-history', {
				cancelToken: source.token
			})
			.then((resp) => {
				// console.log(resp.data)
				let data = resp.data;
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setSummaryHistory', data);

				commit('setStatusSummaryHistory', {
					status: true,
					cancel: null,
					code: code,
				});
			})
			.catch((resp) => {
				// console.log(resp)
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setStatusSummaryHistory', {
					status: 'failed',
					cancel: null,
					code: code,
				});
				dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
			});
		},

		getHistoryData({
			state,
			commit,
			dispatch,
			rootGetters
		}, [
			limit = '',
			page = '',
			keyword = '',
			order_by = '',
			from_date = '',
			to_date = '',
			status = '0,2,3'
		]) {
			if (state.status_history_data.cancel != null && state.status_history_data.status === 'loading')
				state.status_history_data.cancel('Operation canceled by the user.');

			const source = cancelToken.source();

			commit('setStatusHistoryData', {
				status: 'loading',
				cancel: source.cancel,
				code: null,
			});

			let params = new FormData();
			params.append('timezone', rootGetters['config/getTimezone']);

			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			params.append('order_by', order_by);
			params.append('from_date', from_date);
			params.append('to_date', to_date);
			params.append('status', status);

			axios.post('api/v1/request-access/history', params, {
				cancelToken: source.token
			})
			.then((resp) => {
				// console.log(resp.data)
				let data = resp.data;
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setHistoryData', data);

				commit('setStatusHistoryData', {
					status: true,
					cancel: null,
					code: code,
				});
			})
			.catch((resp) => {
				// console.log(resp)
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setStatusHistoryData', {
					status: 'failed',
					cancel: null,
					code: code,
				});
				dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
			});
		},
	}
};

export default history;