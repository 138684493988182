import axios from "axios";
import config from "./config/api";

const instance = axios.create({
  baseURL: config.API_URL,
});
instance.defaults.headers.common["Content-Type"] = config.AUTH_HEADERS['Content-Type'];
instance.defaults.headers.common["Accept"] = config.AUTH_HEADERS['Accept'];
instance.defaults.headers.common["Authorization"] = config.AUTH_HEADERS['Authorization'];
instance.defaults.headers.common["x-app-key"] = config.AUTH_HEADERS['x-app-key'];
instance.defaults.headers.common["x-secret-key"] = config.AUTH_HEADERS['x-secret-key'];
instance.defaults.headers.common["x-authorization"] = config.AUTH_HEADERS['x-authorization'];

export { instance, axios };

export default instance;
