<template lang="pug">
    .not-found
        img.logo-global(alt="" :src="logo")
        h1.text-3xl.mb-4 Page Not Found
        p.mb-6 Please check again the link you visited or you can back to.
        a.btn-primary.mt-6.mr-5(@click="$router.go(-1)") Back
        router-link.btn-primary.mt-6(:to="user && user.role && user.role.label === 'tracking' ? '/tracking-history' : '/'") Home

</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'NotFoundPage',
    data(){
        return {
            logo: this.$store.getters['config/getLogo'],
        };
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    mounted() {
      //
    },
}
</script>

<style lang="sass" scoped>
.not-found
    text-align: center
    .logo-global
        max-width: 250px
        margin: 0 auto 24px auto

    max-width: 420px
    margin: 32px auto

    .btn-primary
        letter-spacing: 1px
    
    h1
        color: #ffc490
</style>