<template lang="pug">
  .blank-info.flex.items-center.justify-center
    .cover-no-data-avaible
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
      span.monospace No Data Available
</template>

<script>
export default {
  name: 'NoDataAvailable',
  // props: ['show'],
}
</script>

<style lang="sass">
.blank-info
  padding: 30px
  width: 100%
  text-align: center
  .cover-no-data-avaible svg
    width: 80px
    height: 80px
    margin: 0 auto
</style>
