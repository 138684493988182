import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList;
var cancelToken = axiosOrigin.CancelToken;

const savedLocation = {
  namespaced: true,
  state: {
    // module
    status: false,

    //savedLocation
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },

    list: {},
  },
  mutations: {
    // module
    setStatus: (state, status) => {
      state.status = status;
    },
    // target
    setStatusList: (state, status) => {
      state.status_list = status;
    },

    setList: (state, data) => {
      state.list = data;
    },
  },
  getters: {},
  actions: {
    async getList({ commit, dispatch, state, rootGetters }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null]) {

      if(state.status_list.cancel != null && state.status_list.status == 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = new FormData();
      params.append('timezone', rootGetters['config/getTimezone']);

      params.append('limit', limit);
      params.append('page', page);
      params.append('keyword', keyword);
      params.append('order_by', order_by);
      params.append('from_date', from_date);
      params.append('to_date', to_date);

      await axios.post('api/v1/location/list', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async create({ dispatch }, [
      location_name     = '',
      coordinate        = '',
      target_id         = '',
      registered_name   = '', // target_name
      address           = '',
      phone             = '',
      ttype             = '',
      bts               = '',
      imsi              = '',
      imei              = '',
      mcc               = '',
      lac               = '',
      ci                = '',
      net               = '',
      provider          = '',
      state             = ''
    ]) {

      let params = new FormData();
      params.append('location_name', location_name);
      params.append('coordinate', coordinate);
      params.append('target_id', target_id);
      params.append('registered_name', registered_name); // target_name
      params.append('address', address);
      params.append('phone', phone);
      params.append('ttype', ttype);
      params.append('bts', bts);
      params.append('imsi', imsi);
      params.append('imei', imei);
      params.append('mcc', mcc);
      params.append('lac', lac);
      params.append('ci', ci);
      params.append('net', net);
      params.append('provider', provider);
      params.append('state', state);

      return axios.post('api/v1/location/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null;
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

  }
};

export default savedLocation;
