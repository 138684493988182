<template lang="pug">
	component(:is="layout")
		router-view
</template>

<script>
    import { mapState } from 'vuex';
	const applayout = 'app';
	export default {
		name: 'MainApp',
		data: function() {
			return {
				favicon: this.$store.getters['config/getFavicon'],
				title: this.$store.getters['config/getTitleGlobal'],
			};
		},
		metaInfo() {
			return {
                // old
				// title: this.title,
				// titleTemplate: (this.title === this.$store.getters['config/getTitleGlobal']  ? this.title : '%s |  ' + this.$store.getters['config/getTitleGlobal']),
                
                title: (this.user && this.user.role && this.user.role.label  && this.user.role.label === 'tracking' ? 'Nemesis Tracking' : this.title),
                titleTemplate: (this.user && this.user.role && this.user.role.label  && this.user.role.label === 'tracking' ? null : (this.title === this.$store.getters['config/getTitleGlobal']  ? this.title : '%s |  ' + this.$store.getters['config/getTitleGlobal'])),
			};
		},
		computed: {
			layout() {
				return (this.$route.meta.layout || applayout ) + '-layout'
			},
            ...mapState('auth', [
                'user',
                // 'profile',
                // 'status_profile'
            ]),
		},
		methods: {
			showLoading(show=true) {
				if (show) {
					this.$swal.fire({
						title: '',
						html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>Loading...</h4></div>',
						showConfirmButton: false,
						allowOutsideClick: false
					});

				} else {
					this.$swal.close();
				}
			},
			error(message=null, data=null) {
				let errors = '';

				if (data !== null) {
					Object.keys(data).forEach(function (item) {
						errors = errors + '<div class="swal2-html-container mt-2" style="display: block;">' + data[item] + '<div>';
					});
				}

				this.$swal.fire({
					icon: 'error',
					title: 'Failed!',
					text: (message!==null ? message : 'Something wrong.'),
					html: errors,
				});
			},
            warning(message=null) {
                // let warnings = '';

                // if (data !== null) {
                //     Object.keys(data).forEach(function (item) {
                //         // warnings += data[item] + '<br><br>';
                //         // warnings += '<div class="swal2-html-container" style="display: block;">' + data[item] + '<br><div>';
                //         warnings = warnings + '<div class="swal2-html-container mt-2" style="display: block;">' + data[item] + '<div>';
                //     });
                // }

                this.$swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: (message!==null ? message : 'Something wrong, please try again.'),
                    // html: warnings,
                    // timer: 3000
                });
            },
            success(message=null, timer=3000) {

                this.$swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: (message!==null ? message : ''),
                    timer: timer,
                });
            },
		},
		created() {
          // set icon
			const favicon = document.querySelector('head link[rel="icon"]');
			favicon.setAttribute('href', this.favicon);
		},

		mounted() {
			Event.$on('showLoading', (status=true) => {
				this.showLoading(status);
			});
			Event.$on('error', (message=null, data=null) => {
				this.error(message, data);
			});
            Event.$on('warning', (message=null) => {
                this.warning(message);
            });
            Event.$on('success', (message=null, timer=3000) => {
                this.success(message, timer);
            });

            console.log(this.user)
		},
	}
</script>
<style lang="sass">
    .save_loading
        width: 140px
        height: 140px
        margin: 0 auto
        animation-duration: 0.5s
        animation-timing-function: linear
        animation-iteration-count: infinite
        animation-name: loadingSweetalert2
        transform-origin: 50% 50%

    @keyframes loadingSweetalert2
        100%
            transform: rotate(-360deg) translate(0,0)

    .tooltip
        display: block !important
        z-index: 10000

        .tooltip-inner
            max-width: 300px
            text-align: center
            // background: black
            background-color: rgba(0, 0, 0, 0.8)
            color: white
            // border-radius: 16px
            border-radius: 5px
            // padding: 5px 10px 4px
            padding: 10px

        .tooltip-arrow
            width: 0
            height: 0
            border-style: solid
            position: absolute
            margin: 5px
            border-color: black
            z-index: 1

        &[x-placement^="top"]
            margin-bottom: 5px

        .tooltip-arrow
            border-width: 5px 5px 0 5px
            border-left-color: transparent !important
            border-right-color: transparent !important
            border-bottom-color: transparent !important
            bottom: -5px
            left: calc(50% - 5px)
            margin-top: 0
            margin-bottom: 0

        &[x-placement^="bottom"]
            margin-top: 5px

        .tooltip-arrow
            border-width: 0 5px 5px 5px
            border-left-color: transparent !important
            border-right-color: transparent !important
            border-top-color: transparent !important
            top: -5px
            left: calc(50% - 5px)
            margin-top: 0
            margin-bottom: 0

        &[x-placement^="right"]
            margin-left: 5px

        .tooltip-arrow
            border-width: 5px 5px 5px 0
            border-left-color: transparent !important
            border-top-color: transparent !important
            border-bottom-color: transparent !important
            left: -5px
            top: calc(50% - 5px)
            margin-left: 0
            margin-right: 0

        &[x-placement^="left"]
            margin-right: 5px

        .tooltip-arrow
            border-width: 5px 0 5px 5px
            border-top-color: transparent !important
            border-right-color: transparent !important
            border-bottom-color: transparent !important
            right: -5px
            top: calc(50% - 5px)
            margin-left: 0
            margin-right: 0

        &[aria-hidden='true']
            visibility: hidden
            opacity: 0
            transition: opacity .15s, visibility .15s

        &[aria-hidden='false']
            visibility: visible
            opacity: 1
            transition: opacity .15s
</style>
