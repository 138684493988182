import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelRecentLocation;
var cancelToken = axiosOrigin.CancelToken;

const recentLocation = {
  namespaced: true,
  state: {
    record_size: null,
    from_date: null,
    to_date: null,

    status: false,
    status_recent_location: {
      status: false,
      cancel: null,
      code: null
    },

    recent_location: {},
  },
  mutations: {
    setRecordSize: (state, data) => {
      state.record_size = data;
    },
    setFromDate: (state, data) => {
      state.from_date = data;
    },
    setToDate: (state, data) => {
      state.to_date = data;
    },

    setStatus: (state, status) => {
      state.status = status;
    },
    setStatusRecentLocation: (state, status) => {
      state.status_recent_location = status;
    },

    setRecentLocation: (state, data) => {
      state.recent_location = data;
    },
  },
  getters: {},
  actions: {
    async getResultData({ state, rootGetters, commit, dispatch }, [key, type, service_source=null, target_id='']) {

      // const default_date = rootGetters['config/getDefaultDate']();

      // let record_size = state.record_size!==null ? state.record_size : rootGetters['config/getDefaultRecordSize'];
      // let from_date = state.from_date!==null ? state.from_date : default_date.from_date;
      // let to_date = state.to_date!==null ? state.to_date : default_date.to_date;

      await dispatch('setLoading');

      await dispatch('getRecentLocation', [key, type, service_source, target_id]);

      commit('setStatus', true);
    },

    async setLoading({ commit, state }) {
      await commit('setStatus', 'loading');

      await commit('setStatusRecentLocation', {
        status: 'loading',
        cancel: state.status_recent_location.cancel,
        code: null
      });
    },

    async setEmpty({ state, commit }) {
      commit('setStatus', false);

      if (state.status_recent_location.cancel !== null && state.status_recent_location.status === 'loading')
        await state.status_recent_location.cancel('Operation canceled by the user.');
      commit('setStatusRecentLocation', {
        status: false,
        cancel: null,
        code: null
      });

      commit('setRecentLocation', {});
    },

    async getRecentLocation({ commit, dispatch, state, rootGetters }, [key, type, service_source, target_id]) {
      
      if (state.status_recent_location.cancel !== null && state.status_recent_location.status === 'loading')
        await state.status_recent_location.cancel('Operation canceled by the user.');

      let service = 'service';
      if (service_source === 'internal') {
        service = 'service-int'
      } else if (service_source === 'external') {
        service = 'service-ext'
      }

      let params = new FormData();
			params.append('timezone', rootGetters['config/getTimezone']);
      params.append('key', key);
      // params.append('type', type);
      // params.append('size', record_size);
      params.append('size', 200);

      const default_date = rootGetters['config/getDefaultDate']();
      // params.append('from_date', from_date);
      // params.append('to_date', to_date);
      params.append('from_date', default_date.to_date);
      params.append('to_date', default_date.to_date);

      params.append('target_id', target_id);

      await axios.post('api/v1/'+service+'/overall/recent-location', params, {
        cancelToken: new cancelToken(function executor(c) {
          cancelRecentLocation = c;
          commit('setStatusRecentLocation', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setRecentLocation', data);

        if (data.features[0].geometry.coordinates && (data.features[0].geometry.coordinates[0]==="" || data.features[0].geometry.coordinates[1]==="")) {

          result.status = 'empty';

        } else if (data.features[0].geometry.coordinates && (data.features[0].geometry.coordinates[0]==="" || data.features[0].geometry.coordinates[1]==="")) {

          result.status = 'partially-empty';
        }
        
        commit('setStatusRecentLocation', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelRecentLocation;
        }

        commit('setStatusRecentLocation', result);
        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },
  },
};

export default recentLocation;
