<template lang="pug">
    .container
        section.grid.grid-cols-4.gap-4.py-4.my-8
            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Total Active Users
                template(v-if="status_active_users.status === 'loading'")
                    .pt-2
                        .loading-request
                template(v-else)
                    h2.text-4xl {{ active_users.total_data || active_users.total_data == 0 ? formatPrice(active_users.total_data) : '-' }}
            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Today request usage
                template(v-if="status_summary_data.status === 'loading'")
                    .pt-2
                        .loading-request
                template(v-else)
                    h2.text-4xl {{ summary_data.today_usage || summary_data.today_usage == 0 ? formatPrice(summary_data.today_usage) : '-' }}
            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Today Remaining request usage
                template(v-if="status_summary_data.status === 'loading'")
                    .pt-2
                        .loading-request
                template(v-else)
                    h2.text-4xl {{ summary_data.remaining_quota || summary_data.remaining_quota == 0 ? formatPrice(summary_data.remaining_quota) : '-' }}
            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Maximum Daily request Usage
                template(v-if="status_summary_data.status === 'loading'")
                    .pt-2
                        .loading-request
                template(v-else)
                    h2.text-4xl {{ summary_data.total || summary_data.total == 0 ? formatPrice(summary_data.total) : '-' }}

        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-xl.font-bold.uppercase Active Users
                button.btn-primary-o(
                    type='submit'
                    @click="modalForm(true)"
                    ) Add New

            .box__body
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableTheadActiveUsers" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                        tbody
                            tr(v-if="status_active_users.status === 'loading'")
                                td.text-center(:colspan="tableTheadActiveUsers.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="active_users.data && active_users.data.length > 0")
                                tr(v-for="(data, index) in active_users.data" :key="index")
                                    td {{ data.name ? data.name : 'No Name' }}
                                    td {{ data.quota ? data.quota : '0' }}
                                    td
                                        .text-xs {{ data.created_at_view ? data.created_at_view : (data.created_at ? datetimeFormat(data.created_at) : '-') }}
                                    td
                                        .text-xs {{ data.expired_at_view ? data.expired_at_view : (data.expired_at ? datetimeFormat(data.expired_at) : '-') }}
                                    td
                                        .text-xs {{ data.updated_at ? datetimeFormat(data.updated_at) : '-' }}
                                    td
                                        .flex.items-center.justify-end
                                            button.btn-default.mr-4(
                                                type='submit' 
                                                @click="modalForm(true, data)"
                                            ) Edit
                                            button.btn-primary-o(
                                                type='submit'
                                                @click="stopAction(data.id)"
                                            ) Stop
                            tr(v-else)
                                td.text-center(:colspan="tableTheadActiveUsers.length") No Data Available
            .box__footer(v-if="status_active_users.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )
        .divider
            span
            span
            span

        .overlay(v-bind:class="{'is-show': modalOverlay}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold {{ typeForm }} Active User
                        button.btn-icon(
                            @click="modalForm(false)"
                        )
                            IconSet(
                                type="close"
                            )
                    .box-overlay__body
                        .field
                            .field__label
                                label(for="search") User
                            .field__form
                                .relative
                                    input.field-style.box-user(
                                        type="text" 
                                        placeholder="Search user (write min 3 characters) or choose at the bellow"
                                        v-model="user_keyword"
                                        @keyup="searchUser()"
                                    )
                                    .field-icon
                                        IconSet(
                                            type="search"
                                        )
                                    .box-find-selected.field-style(v-if="user_selected")
                                        input.field-style.field-style--disabled( 
                                            v-if="typeForm == 'Edit'"
                                            v-model="user_keyword"
                                            disabled
                                        )
                                        //- ) {{ user_keyword }}
                                        .flex.items-center.justify-between.px-2(v-else)
                                            .flex.items-center
                                                figure.mr-3
                                                    //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                                span {{user_keyword}}
                                            button.btn-icon-24(@click="setEmptyUser")
                                                IconSet(type="close")
                                    .box-user(v-else)
                                        .fields-box__item.flex.items-center.justify-center.w-full(
                                            v-if="status_tactical_users.status === 'loading'"
                                        )
                                            Spinner(show="true" size="48")
                                        template(v-else-if="tactical_users && tactical_users.length > 0")
                                            span.block.text-xs.font-bold.uppercase.mb-4 Choose user
                                            ul
                                                li.cursor-pointer(
                                                    class="hover:bg-black"
                                                    v-for="item in tactical_users"
                                                    :key="item.id"
                                                    @click="selectUser(item)"
                                                
                                                )
                                                    .flex.items-center.justify-between.py-4
                                                        .flex.items-center
                                                            .mr-2
                                                                //- figure.rounded-full.w-6.h-6.overflow-hidden
                                                                    img(:src="item.avatar").w-full.h-full.object-fit
                                                            span.block {{ item.name }}
                                                        span.block.items-end {{ item.email }}

                                        .fields-box__item.flex.items-center.justify-center.w-full(v-else)
                                            | No data available.
                                    .mt-2
                                        span.text-xs.text-red-400(v-if="$v.model.user_id.$error") This user is required.
                                        span.text-xs.text-red-400(v-if="error_user_id !== ''") {{ error_user_id }}
                        .field
                            .field__label
                                label(for="search") Quota
                            .field__form
                                input.field-style.box-user(
                                    type="number" 
                                    placeholder="ex: 20" 
                                    v-model="model.quota"
                                )
                                //- status start here
                                .mt-2
                                    span.text-xs.text-red-400(v-if="$v.model.quota.$error") This quota is required.
                                    span.text-xs.text-red-400(v-if="error_quota !== ''") {{ error_quota }}
                        .field
                            .field__label
                                label(for="search") Expired At
                            .field__form
                                .relative.field-style
                                    date-range-picker.w-full.field-style__date(
                                        v-if="expiredDatetime.startDate && expiredDatetime.endDate" 
                                        ref="picker"
                                        v-model="expiredDatetime"
                                        singleDatePicker=true
                                        :ranges="ranges"
                                        timePicker=true
                                        timePicker24Hour=true
                                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm' }" 
                                        :minDate="minDate"
                                        @update="pickerUpdateForm($event)" 
                                        autoApply=true
                                    )
                                        //- :date-format="dateFormat"
                                        //- opens="right"
                                        //- :maxDate="maxDate"
                                        div(
                                            slot="input" 
                                            slot-scope="picker") 
                                                | {{ picker.startDate | date}}

                                    .field-icon
                                        IconSet(type="calendar")
                                    //- input.field-style.box-user(
                                    //-     type="text"
                                    //-     placeholder="Please choose expired date"
                                    //-     v-model="model.expired_at"
                                    //- )
                                    //- .field-icon
                                    //-     IconSet(
                                    //-         type="calendar"
                                    //-     )
                                //- status start here
                                .mt-2
                                    span.text-xs.text-red-400(v-if="$v.model.expired_at.$error") This expired date is required.
                                    span.text-xs.text-red-400(v-if="error_expired_at !== ''") {{ error_expired_at }}

                        .field.flex.justify-end
                            button.btn-primary(type='submit' @click="saveAction()") Save


</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import moment from 'moment';
import JsonExcel from "vue-json-excel";
import { required } from "vuelidate/lib/validators";

export default {
    name: 'DahsboardActivity',
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        IconSet,
        JsonExcel,
    },
    data() {
        return {
            modalOverlay: false,
            typeForm: 'Create',
            tableTheadActiveUsers: [
                {
                    name: 'User',
                },{
                    name: 'Quota',
                },{
                    name: 'Created At',
                },{
                    name: 'Expired At',
                },{
                    name: 'Updated At',
                },{
                    name: '',
                }
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,

            model: {
                id:'',
                user_id:'',
                quota:'',
                expired_at:'',
            },
             // error field
            error_user_id: '',
            error_quota: '',
            error_expired_at: '',

            user_keyword:'',
            user_id:null,
            user_selected:false,

            from_date: '',
            to_date: '',
            expiredDatetime: {
                startDate: moment().add(1,'days').format('YYYY-MM-DD HH:mm'),
                endDate: moment().add(1,'days').format('YYYY-MM-DD HH:mm'),
            },
            minDate: moment().subtract({ days: 1}).format('YYYY-MM-DD HH:mm'),
            ranges: {}
        }
    },
    validations: {
        model: {
            id: {},
            user_id: {
                required,
            },
            quota: {
                required,
            },
            expired_at: {
                required,
            },
        },
    },
    computed: {
        ...mapState('dashboard', [
            'status_summary_data',
            'summary_data',
            'status_active_users',
            'active_users',
            'status_tactical_users',
            'tactical_users',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'DashboardPage') {
                // this.getApprovalData();
                this.getSummaryData();
                this.getActiveUsersData();
            }
        },
        active_users(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
            if (newData && newData.data && newData.data.length > 0) {
                this.getDataCsv(newData.data)
            }
        },
        user_keyword(newData) {
            // if (newData.length < 3)
            //     this.$store.commit('dashboard/setTacticalUsers', {});
        },
    },
    methods: {
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('dashboard/setTacticalUsers', {});
                    self.$store.dispatch('dashboard/getTacticalUsers', [10, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.model.user_id = user.id
            this.user_selected = true
            this.$store.commit('dashboard/setTacticalUsers', {});
        },
        setEmptyUser() {
            this.user_keyword = ''
            this.model.user_id=null
            this.user_selected = false
            this.getTacticalUsersData();
        },
        setFormDefault(){
            this.user_keyword = ''
            this.user_selected = false
            this.model = {
                id:'',
                user_id:'',
                quota:'',
                expired_at: moment(this.expiredDatetime.startDate).format('YYYY-MM-DD HH:mm:ss'),
            }
            this.expiredDatetime = {
                startDate: moment().add(1,'days').format('YYYY-MM-DD HH:mm'),
                endDate: moment().add(1,'days').format('YYYY-MM-DD HH:mm'),
            }
            this.minDate = moment().subtract({ days: 1}).format('YYYY-MM-DD HH:mm')
        },
        modalForm(status=true, data=null) {
            this.modalOverlay = status
            if (status) {
                this.getTacticalUsersData();
                if (data) {
                    this.typeForm = 'Edit'
                    this.model = {
                        id: data.id,
                        user_id: data.user_id,
                        quota: data.quota,
                        expired_at: this.datetimeFormat(data.expired_at,"YYYY-MM-DDThh:mm:ss", "YYYY-MM-DD HH:mm"),
                    }
                    this.expiredDatetime = {
                        startDate: this.datetimeFormat(data.expired_at,"YYYY-MM-DDThh:mm:ss", "YYYY-MM-DD HH:mm"),
                        endDate: this.datetimeFormat(data.expired_at,"YYYY-MM-DDThh:mm:ss", "YYYY-MM-DD HH:mm")
                    }
                    this.user_keyword = data.name
                    this.user_selected = true
                } else {
                    this.typeForm = 'Create'
                    this.setFormDefault()
                }
            } else {
                this.setFormDefault()
            }
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    datetime: item.created_at? this.datetimeFormat(item.created_at) : '-',
                    key: item.key? item.key : '-',
                    last_update: item.updated_at? item.updated_at : '-',
                    status: item.status?item.status:'-',
                };
            })
        },
        getTacticalUsersData() {
            // for dispatch get data
            if (this.route_name === 'DashboardPage') {
                this.$store.dispatch('dashboard/getTacticalUsers', [10, 1, null]);
            }
        },
        getSummaryData() {
            // for dispatch get data
            if (this.route_name === 'DashboardPage') {
                this.$store.dispatch('dashboard/getSummaryData');
            }
        },
        getActiveUsersData() {
            // for dispatch get data
            if (this.route_name === 'DashboardPage') {
                this.$store.dispatch('dashboard/getActiveUsers', [this.items_per_page, (this.page + 1), this.keyword, this.order_by, this.from_date, this.to_date]);
            }
        },
        datetimeFormat(datetime, from_format="YYYY-MM-DDThh:mm:ssZ", to_format="DD MMM YYYY HH:mm"){
            return this.$store.getters['config/getDatetimeFormat'](datetime, from_format, to_format)
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            // console.log('moment')
            // console.log(date.getTime())
            // // console.log(moment(date.getTime())._d)
            // console.log(moment(date.getTime()).subtract({ hours: 12})._d)
            // console.log('new Date')
            // console.log(new Date())
            // console.log(Date.now())
            if (!classes.disabled) {
                classes.disabled = date.getTime() <= Date.now();
                // classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d <= new Date();
            }
            return classes;
        },

        defaultData(){
            this.model.expired_at = moment(this.expiredDatetime.startDate).format('YYYY-MM-DD HH:mm:ss');
        },
        // defaultFilter(){
        //     let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
        //     const default_date = this.$store.getters['config/getDefaultDate'](def);

        //     this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
        //     this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
        //     this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
        //     this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

        //     this.items_per_page=10
        // },
        async pickerUpdateForm(e) {
            this.model.expired_at = moment(this.expiredDatetime.startDate).format('YYYY-MM-DD HH:mm:ss');

        },
        saveAction(){
            Event.$emit('showLoading', true);
            let action = ''
            let params = []
            if (this.typeForm == 'Create') {
                action = 'createAction'
                params = [this.model.user_id, this.model.quota, this.model.expired_at]
            } else {
                action = 'updateAction'
                params = [this.model.id, this.model.quota, this.model.expired_at]
                
            }

            this.$store.dispatch('dashboard/'+action, params)
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.page = 0;

                        this.getActiveUsersData();
                        this.modalForm(false);
                        this.$swal.fire({
                            icon: 'success',
                            title: resp.data.message?resp.data.message:'Success',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            Event.$emit('error', resp.data.message);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });

        },
        stopAction(id) {
            var confirm = true
            var title = 'Are you sure you want to STOP?'
            // if (status !== 1) {
            //     title = 'Are you sure you want to REJECT this request?'
            // }

            if(confirm) {
                this.$swal.fire({
                    icon: 'warning',
                    title: title,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        Event.$emit('showLoading', true);
                        this.$store.dispatch('dashboard/stopAction', [id])
                        .then((resp) => {
                            if (resp.result === 'success') {
                                this.page = 0;

                                this.getActiveUsersData();
                                // this.getSummaryData();

                                this.$swal.fire({
                                    icon: 'success',
                                    title: resp.data.message?resp.data.message:'Success',
                                    timer: 3000,
                                });
                            } else {
                                if (resp.data.message) {
                                    Event.$emit('error', resp.data.message);
                                } else {
                                    Event.$emit('error');
                                }
                            }
                        });
                    }
                });
            }
        },
    },
    mounted() {
        // this.defaultFilter()
        // this.getApprovalData();
        this.defaultData();
        this.getSummaryData();
        this.getActiveUsersData();
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
    },
}
</script>

<style lang="sass">
    .field-style__date
        .reportrange-text
            border: 0px
            height: 48px
            padding: 0px
            line-height: 48px
        .daterangepicker.show-calendar
            min-width: 100px !important
        .drp-buttons
            button
                @apply text-yellow-500

</style>


<style lang="sass" scoped>
    .callout
        @apply block bg-yellow-100 text-yellow-900 p-4 rounded
        span
            @apply block mb-2 uppercase text-xs font-bold
    %btn
        @apply rounded text-sm inline-flex items-center justify-center px-4 py-2

    .btn-primary-o
        border: 1px solid $color-light
        @extend %btn
        &:hover
            color: $color-dark
            border: 1px solid $color-primary
            background-color: $color-primary


    .btn-default
        @extend %btn
        &:hover
            color: $color-dark
            background-color: $color-primary

    .main-table
        @apply w-full
        table.table 
            @apply w-full
            tr
                th,
                td
                    @apply py-3 px-2
                    border-bottom: 1px solid darken($color-bg, 8%)

                th
                    @apply uppercase text-xs text-left opacity-75

                &:hover
                    td
                        background: darken($color-bg, 4%)

    .box-user
        border: 1px solid #486053
        max-height: 200px
        overflow: auto
        @apply p-6

    .box-find-selected
        // background-color: $color-primary
        // padding-top: 2px
        margin-top: 1px
        background: rgba(5, 6, 29, 1)
        @apply absolute inset-0 rounded px-0
        // border-color: #2d3061
    
    .loading-request
        width: 40px
        height: 40px
        border-radius: 9999px
        border-width: 3px
        border-style: solid
        border-color: $color-primary darken($color-primary, 50%) $color-primary $color-primary
        transform: rotate(0deg)
        animation: rotate 1s infinite

    @keyframes rotate
        from
            transform: rotate(0deg)
        to
            transform: rotate(360deg)
</style>