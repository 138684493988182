<template lang="pug">
    .container
        section.grid.grid-cols-3.gap-4.py-4.my-8
            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Total users
                template(v-if="status_summary_history.status === 'loading'")
                    .pt-2
                        .loading-request
                template(v-else)
                    h2.text-4xl {{ summary_history.total_users || summary_history.total_users == 0 ? formatPrice(summary_history.total_users) : '-' }}

            //- .callout
                .block.text-xs.uppercase.font-bold.mb-2 Total History Activation
                template(v-if="status_summary_history.status === 'loading'")
                    .pt-2
                        .loading-request
                template(v-else)
                    h2.text-4xl {{ summary_history.total_history_activation || summary_history.total_history_activation == 0 ? formatPrice(summary_history.total_history_activation) : '-' }}

            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Total Quota Shared
                
                h2.text-4xl -
                //- .pt-2(v-if="status_summary_history.status === 'loading'")
                    .loading-request

                //- h2.text-4xl(v-else) {{ summary_history.total_quota_shared || summary_history.total_quota_shared == 0 ? formatPrice(summary_history.total_quota_shared) : '-' }}

            //- .callout
                .block.text-xs.uppercase.font-bold.mb-2 Total Quota Used
                
                .pt-2(v-if="status_summary_history.status === 'loading'")
                    .loading-request

                h2.text-4xl(v-else) {{ summary_history.total_quota_used || summary_history.total_quota_used == 0 ? formatPrice(summary_history.total_quota_used) : '-' }}

            .callout
                .block.text-xs.uppercase.font-bold.mb-2 Average Daily request
                
                .pt-2(v-if="status_summary_history.status === 'loading'")
                    .loading-request

                h2.text-4xl(v-else) {{ summary_history.avg_quota_daily || summary_history.avg_quota_daily == 0 ? formatPrice(summary_history.avg_quota_daily) : '-' }}

        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-xl History Users
            .box__body
                .box-filter.mb-4
                    .flex.items-center
                        .btn-primary-o.relative.mr-3
                            IconSet(type="calendar" size="s")
                            .btn-date-range.ml-3
                                date-range-picker(
                                    v-if="dateRange.startDate && dateRange.endDate" 
                                    ref="picker" 
                                    v-model="dateRange" 
                                    @update="pickerUpdate($event)" 
                                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                    :date-format="dateFormat" 
                                    opens="right"
                                    :ranges="default_ranges"
                                    autoApply=true
                                )
                                    div(
                                        slot="input" 
                                        slot-scope="picker") 
                                            | {{ picker.startDate | date}} - {{ picker.endDate  | date}}
                        //- Export to CSV
                        JsonExcel.btn-primary-o(
                            v-if="history_data.data && history_data.data.length > 0"
                            type = "csv"
                            :title="[exportTitle, from_date + ' - ' + to_date]"
                            :name="csvName"
                            :data="csvData"
                        )
                            IconSet(type="download" size="s")
                            span.m-hide.ml-3
                            | Download CSV
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableTheadHistory" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                                //- th
                                    .flex.justify-end
                                        .w-32.text-center status
                        tbody
                            tr(v-if="status_history_data.status === 'loading'")
                                td.text-center(:colspan="tableTheadHistory.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="history_data.data && history_data.data.length > 0")
                                tr(v-for="(data, index) in history_data.data" :key="index")
                                    td {{ data.name? data.name : '-' }}
                                    td {{ data.quota? data.quota : '-' }}
                                    td
                                        .text-xs {{ data.created_at_view ? data.created_at_view : (data.created_at ? datetimeFormat(data.created_at) : '-') }}
                                    td
                                        .text-xs {{ data.expired_at_view ? data.expired_at_view : (data.expired_at ? datetimeFormat(data.expired_at) : '-') }}
                                    td
                                        template(v-if="data.status === 'approved'")
                                            .bg-blue-100.text-blue-800.px-2.py-1.rounded.uppercase.text-xs.font-bold.inline-block.w-24.text-center {{ data.status }}
                                        template(v-else-if="data.status === 'rejected'")
                                            .bg-gray-100.text-gray-800.px-2.py-1.rounded.uppercase.text-xs.font-bold.inline-block.w-24.text-center {{ data.status }}
                                        template(v-else-if="data.status === 'not active'")
                                            .bg-red-100.text-red-800.px-2.py-1.rounded.uppercase.text-xs.font-bold.inline-block.w-24.text-center {{ data.status }}
                                        template(v-else)
                                            .bg-red-100.text-red-800.px-2.py-1.rounded.uppercase.text-xs.font-bold.inline-block.w-24.text-center {{ data.status }}
                            tr(v-else)
                                td.text-center(:colspan="tableTheadHistory.length") No Data Available   
            .box__footer(v-if="status_history_data.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

            

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import moment from 'moment';
import JsonExcel from "vue-json-excel";

export default {
    name: 'HistoryActivity',
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        DateRangePicker,
        IconSet,
        JsonExcel,
    },
    data() {
        return {
            tableTheadHistory: [
                {
                    name: 'User',
                },{
                    name: 'Quota',
                },{
                    name: 'Created At',
                },{
                    name: 'Expired At',
                },{
                    name: 'Status',
                    // action: true,
                }
            ],

            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            order_by: '',
            keyword: '',
            timeout: null,

            csvName: 'history_data_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            csvData: [],

            exportTitle: 'History Data',
        }
    },
    computed: {
        ...mapState('history', [
            'status_summary_data',
            'summary_data',
            'status_summary_history',
            'summary_history',
            'status_history_data',
            'history_data',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'HistoryPage') {
                // this.getSummaryData();
                this.getSummaryHistory();
                this.getHistoryData();
            }
        },
        history_data(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
            if (newData && newData.data && newData.data.length > 0) {
                this.getDataCsv(newData.data)
            }
        },
        items_per_page() {
            this.getHistoryData();
        },
        page() {
            this.getHistoryData();
        },
    },
    methods: {
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    user: item.name? item.name : '-',
                    quota: item.quota? item.quota : '-',
                    created_at: item.created_at? this.datetimeFormat(item.created_at) : '-',
                    expired_at: item.expired_at? this.datetimeFormat(item.expired_at) : '-',
                    status: item.status?item.status:'-',
                };
            })
        },
        getSummaryData() {
            // for dispatch get data
            if (this.route_name === 'HistoryPage') {
                this.$store.dispatch('history/getSummaryData');
            }
        },
        getSummaryHistory() {
            // for dispatch get data
            if (this.route_name === 'HistoryPage') {
                this.$store.dispatch('history/getSummaryHistory');
            }
        },
        getHistoryData() {
            // for dispatch get data
            if (this.route_name === 'HistoryPage') {
                this.$store.dispatch('history/getHistoryData', [this.items_per_page, (this.page + 1), this.keyword, this.order_by, this.from_date, this.to_date]);
            }
        },
        datetimeFormat(datetime, from_format="YYYY-MM-DDThh:mm:ssZ", to_format="DD MMM YYYY HH:mm"){
            return this.$store.getters['config/getDatetimeFormat'](datetime, from_format, to_format)
        },
        /* eslint-disable no-unused-vars */
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },

        defaultFilter(){
            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.items_per_page=10
        },
        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
    
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.page = 0;
                this.getHistoryData()
            }
        },
    },
    mounted() {
        this.defaultFilter();
        // this.getSummaryData();
        this.getSummaryHistory();
        this.getHistoryData();
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
    },
}
</script>

<style lang="sass">
    .btn-date-range
        .reportrange-text
            border: none
            padding: 0px

        .calendars.row
            display: flex
</style>

<style lang="sass" scoped>
    
</style>