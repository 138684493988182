import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList, cancelSearchList;
var cancelToken = axiosOrigin.CancelToken;

const target = {
  namespaced: true,
  state: {
    // module
    status: false,

    //target
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },

    status_search_list: {
      status: false,
      cancel: null,
      code: null,
    },

    status_detail: {
      status: false,
      cancel: null,
      code: null,
    },
    list: {},
    search_list: {},
    detail: {},
  },
  mutations: {
    // module
    setStatus: (state, status) => {
      state.status = status;
    },
    // target
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusSearchList: (state, status) => {
      state.status_search_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },

    setList: (state, data) => {
      state.list = data;
    },
    setSearchList: (state, data) => {
      state.search_list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
  },
  getters: {},
  actions: {
    async getList({ commit, dispatch, state, rootGetters }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, param='', no_case_or_cases=null]) {

      if(state.status_list.cancel != null && state.status_list.status == 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        no_case_or_cases: no_case_or_cases,
        timezone: rootGetters['config/getTimezone']
      };

      let urlApi = 'api/v1/single-target/list' 
      if (param === 'dashboard')
        urlApi = 'api/v1/single-target/list-cache';

      axios.get(urlApi, {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        if (param === 'dashboard')
          result.status = 'empty';

        commit('setStatusList', result);

        if (param !== 'dashboard')
        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    async getSearchList({ commit, dispatch, state, rootGetters }, [limit=null, page=null, keyword=null, order_by=null, from_date=null, to_date=null, param='', no_case_or_cases=null]) {

      if (state.status_search_list.cancel !== null && state.status_search_list.status === 'loading')
        await state.status_search_list.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        keyword: keyword,
        order_by: order_by,
        from_date: from_date,
        to_date: to_date,
        no_case_or_cases: no_case_or_cases,
        timezone: rootGetters['config/getTimezone']
      };

      let urlApi = 'api/v1/single-target/list';
      if (param === 'dashboard')
        urlApi = 'api/v1/single-target/list-cache';

      axios.get(urlApi, {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelSearchList = c;
          commit('setStatusSearchList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        let data = resp.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setSearchList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusSearchList', result);
      })
      .catch((resp) => {
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelSearchList;
        }

        if (param === 'dashboard')
          result.status = 'empty';

        commit('setStatusSearchList', result);

        if (param !== 'dashboard')
        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    getDetail({ commit, dispatch }, id) {
      commit('setStatusDetail', {
        status: 'loading',
        cancel: null,
        code: null
      });
      let params = new FormData();
      params.append('id', id);
    
      return axios.post('api/v1/single-target', params)
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data;
        let code = resp.response && resp.response.status ? resp.response.status : null
        if (Object.keys(data).length===0) {
          commit('setStatusDetail', {
            status: 'empty',
            cancel: null,
            code: code
          });
        } else {
          commit('setStatusDetail', {
            status: true,
            cancel: null,
            code: code
          });
        }
        commit('setDetail', data);
        return 'success';
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        commit('setStatusDetail', {
          status: 'failed',
          cancel: null,
          code: code
        });
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return 'failed';
      });
    },

    create({ dispatch }, [
      target_name     = null,
      clues           = null,
      // label           = null,
      // photo           = null,
      cases           = null,
      // description     = null,
      additional_info = null
    ]) {
      let params = new FormData();
      params.append('target_name', target_name);
      params.append('clues', clues);
      // params.append('label', label);
      // params.append('photo', photo);
      if (cases !== '' && cases !== '[]' && cases !== null)
        params.append('cases', cases);
      // params.append('description', description);
      params.append('additional_info', additional_info);

      return axios.post('api/v1/single-target/create', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    // multiple photos
    createNew({ dispatch }, [
      target_name     = null,
      clues           = null,
      label           = null,
      photos          = null,
      cases           = null,
      description     = null,
      additional_info = null
    ]) {
      let params = new FormData();
      params.append('target_name', target_name);
      params.append('clues', clues);
      params.append('label', label);
      // params.append('photos', photos);
      params.append('cases', cases);
      params.append('description', description);
      params.append('additional_info', additional_info);
      
      for (let index = 0; index < photos.length; index++) {
        // const element = photos[index];
        // console.log('element')
        // console.log(element)
        params.append('photos', photos[index]);
        
      }
      // console.log('params')
      // console.log(params)
      // console.log(params.get('photos'))
      // return {result: 'failed', data: null};

      return axios.post('api/v1/single-target/create-new', params)
      .then((resp) => {

        if (resp.status === 200 && resp.data.message === 'Data Successfully Created') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ dispatch }, [
      id,
      target_name     = null,
      clues           = null,
      // label           = null,
      // photo           = null,
      cases           = null,
      // description     = null,
      additional_info = null
    ]) {

      let params = new FormData();
      params.append('id', id);
      params.append('target_name', target_name);
      params.append('clues', clues);
      // params.append('label', label);
      // params.append('status', status);
      // params.append('photo', photo);
      if (cases !== '' && cases !== '[]' && cases !== null)
        params.append('cases', cases);
      // params.append('description', description);
      params.append('additional_info', additional_info);
    
      return axios.post('api/v1/single-target/update', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    // multiple photos
    updateNew({ dispatch }, [
      id,
      target_name     = null,
      clues           = null,
      label           = null,
      photos          = null,
      cases           = null,
      description     = null,
      additional_info = null
    ]) {

      let params = new FormData();
      params.append('id', id);
      params.append('target_name', target_name);
      params.append('clues', clues);
      params.append('label', label);
      // params.append('status', status);
      // params.append('photos', photos);
      params.append('cases', cases);
      params.append('description', description);
      params.append('additional_info', additional_info);
    
      for (let index = 0; index < photos.length; index++) {
        params.append('photos', photos[index]);        
      }

      return axios.post('api/v1/single-target/update-new', params)
      .then((resp) => {
        // console.log(resp.data)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Updated') {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, id) {

      let params = new FormData();
      params.append('id', id);

      return axios.post('api/v1/single-target/delete', params)
      .then((resp) => {
        // console.log(resp)
        
        if (resp.status === 200 && resp.data.message === 'Data Successfully Deleted') {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        let code = resp.response && resp.response.status ? resp.response.status : null
        dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },
  }
};

export default target;
