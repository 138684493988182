import axios from "@/axios-main";
import axiosOrigin from "axios";

var cancel
var cancelToken = axiosOrigin.CancelToken;

const activityLog = {
	namespaced: true,
	state: {
		// module
		status: false,

		// tracking
		status_list: {
			status: false,
			cancel: null,
			code: null,
		},
		list: {},
	},
	mutations: {
		// module{{ status_list.status }}
		setStatus: (state, status) => {
			state.status = status;
		},

		// tracking
		setStatusList: (state, status) => {
			state.status_list = status;
		},
		setList: (state, data) => {
			state.list = data;
		},
	},
	getters: {},
	actions: {
		async getList({
			commit,
			dispatch,
			state,
			rootGetters
		}, [limit = '', page = '', keyword = '', from_date = '', to_date = '', device_id = 'all']) {
			if (state.status_list.cancel != null && state.status_list.status == 'loading') {
				await state.status_list.cancel('Operation canceled by the user.');
			}

			let params = new FormData();
			params.append('timezone', rootGetters['config/getTimezone']);

			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			params.append('from_date', from_date);
			params.append('to_date', to_date);
			params.append('device_id', device_id);
			// params.append('client_id', 117);
			// params.append('client_id', 'all');

			axios.post('api/v1/tracking-target/list', params, {
					// cancelToken: source.token,
					cancelToken: new cancelToken(function executor(c) {
						cancel = c;
						commit('setStatusList', {
							cancel: c,
							status: 'loading',
							code: null
						});
					}),
				})
				.then((resp) => {
					let data = resp.data;
					let code = resp.response && resp.response.status ? resp.response.status : null;

					commit('setList', data);

					if (Object.keys(data).length === 0) {
						commit('setStatusList', {
							status: 'empty',
							cancel: null,
							code: code
						});
					} else {
						commit('setStatusList', {
							status: true,
							cancel: null,
							code: code
						});
					}
				})
				.catch((resp) => {
					let data = {
						status: 'failed',
						cancel: null,
						code: resp.response && resp.response.status ? resp.response.status : null,
					};

					if (axiosOrigin.isCancel(resp)) {
						data.status = 'loading';
						data.cancel = cancel;
						// data.cancel = source.cancel;
					}

					commit('setStatusList', data);
					dispatch('errors/handleApiResponse', data.code, {
						root: true
					});
				});
		},
	}
};

export default activityLog;